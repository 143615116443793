import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  menuItem: {
    paddingLeft: '46px',
  },
  menuItemDisabled: {
    cursor: 'default', 
    opacity: '0.5',
  },
  listItemText: {
    lineHeight: '1',
    margin: '0',
  },
  listItemTextSpan: {
    fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif',
    lineHeight: '1',
  },
  listItemTextSpanBold: {
    fontWeight:'bold'
  },
})

function ShowTooltip({ tooltipText, children }) {
    return tooltipText ? (
      <Tooltip title={tooltipText} enterTouchDelay={0} arrow 
        PopperProps={{
            modifiers: [
            {
                name: "offset",
                options: {
                offset: [0, -15],
                },
            },
            ],
        }}
      >
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    );
}

function CustomMenuItem({ to, href, onClick, startIcon, text, textBold, subText, tooltipText, disabled, extraPaddingTop, extraPaddingBottom }) {
    const classes = useStyles()
    const isExternal = !!href;
    const menuItemBaseStyle = {minHeight: '36px', width: '100%', padding: '6px 16px', ...(extraPaddingTop && {paddingTop: '10px'}), ...(extraPaddingBottom && {paddingBottom: '10px'})};
    const listItemTextBaseStyle = {fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', lineHeight: '1'};
  
  return (
    <ShowTooltip 
        tooltipText={tooltipText}
      >
        <MenuItem component={isExternal ? 'a' : (disabled || onClick ? "button" : Link)}
            to={isExternal ? undefined : (disabled ? undefined : to)}
            href={isExternal ? (disabled ? undefined : href) : undefined}
            target={isExternal ? "_blank" : undefined}
            rel={isExternal ? "noopener noreferrer" : undefined}
            onClick={onClick}
            style={startIcon ? {...menuItemBaseStyle} : {...menuItemBaseStyle, paddingLeft: '46px'}}                   
            className={disabled ? classes.menuItemDisabled : undefined }
            disabled={disabled}                                
        >
        {startIcon && (
            <ListItemIcon style={{minWidth:'30px'}}>
            {startIcon}
            </ListItemIcon>
        )}
        {!subText && (
        <ListItemText className={classes.listItemText} style={{flex: 'none'}}><span style={textBold ? {...listItemTextBaseStyle, fontWeight:'bold'} : {...listItemTextBaseStyle}}>{text}</span></ListItemText>
        )}
        {subText && (
        <ListItemText className={classes.listItemText} style={{flex: 'none'}}>
          <div style={{display:"flex", flexDirection: "column", margin: 0}}>
            <span style={textBold ? {...listItemTextBaseStyle, fontWeight:'bold', lineHeight:'1.5'} : {...listItemTextBaseStyle, lineHeight:'1.5'}}>{text}</span>
            <span style={{...listItemTextBaseStyle, fontSize: '0.688rem', lineHeight:'1.5'}}>{subText}</span>
          </div>
        </ListItemText>
        )}
        {isExternal && (
            <ListItemIcon style={{minWidth:'20px', marginLeft:'0.25rem'}}>
              <OpenInNewIcon style={{ fontSize: '1rem' }} />
            </ListItemIcon>
        )}
        </MenuItem>
    </ShowTooltip>
  );
}

export default CustomMenuItem;