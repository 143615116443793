import {Button} from '@material-ui/core'
import PlaceIcon from '@mui/icons-material/Place';
import MapIcon from '@mui/icons-material/Map';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function MapAndLocationButtons() {
    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <Button color="primary" 
                style={{backgroundColor: '#dd1f26', lineHeight: '1', padding: '8px 14px 8px 10px'}}
                variant="contained" 
                size="small" 
                startIcon={<PlaceIcon />} 
                endIcon={<OpenInNewIcon style={{ fontSize: '0.875rem', position: 'absolute', top: '2px', right: '2px' }} />}
                href="https://maps.app.goo.gl/ER9VDNCwooSCozun6" 
                target="_blank">                        
                Locate Justice Place
            </Button>

            <Button color="primary"
                style={{backgroundColor: '#dd1f26', lineHeight: '1', padding: '8px 14px 8px 10px'}} 
                variant="contained" 
                size="small" 
                startIcon={<MapIcon />} 
                endIcon={<OpenInNewIcon style={{ fontSize: '0.875rem', position: 'absolute', top: '2px', right: '2px' }} />}
                href="https://cisgmoot.org/map-of-hearing-rooms" 
                target="_blank">                        
                Map of Hearing Rooms
            </Button>
        </div>
    )
}

export default MapAndLocationButtons;