// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging,getToken,onMessage } from "firebase/messaging";
import {isDev} from "./devUtils";
import {setStorageValue} from "./localStorageUtils";

const stagingFirebaseConfig = {
    apiKey: "AIzaSyA95daZoJ5lOzx9-CgbFNA7MShC9-wVYS0",
    authDomain: "vis-east-staging.firebaseapp.com",
    projectId: "vis-east-staging",
    storageBucket: "vis-east-staging.firebasestorage.app",
    messagingSenderId: "1083541927720",
    appId: "1:1083541927720:web:726c7dba834e70ef0cc21d"
};

const productionFirebaseConfig = {
    apiKey: "AIzaSyDent1FgMzBXTp8j-EgxjaG_WYmXhpiv64",
    authDomain: "vis-east-production.firebaseapp.com",
    projectId: "vis-east-production",
    storageBucket: "vis-east-production.firebasestorage.app",
    messagingSenderId: "1097062990373",
    appId: "1:1097062990373:web:a4ea7c218c55f722edf5cc"
}

const stagingPublicKey = 'BG39YlNon25wWpfGu0laxSNn1l4zz4fu-IUw2fWRBkcGsd7LmyO1kpHExnpaB8rrEjMzweaCg0SV1Ip8DhD15qc'
const productionPublicKey = 'BNItHMwlQ6SEpXtCEF-NW2Apy31g7R8vjrvbvRjmYYOr3T2UKtMV1UWXcRuBrg1eyVRC7jR_RqInloVuR5EKMQ8'
// Initialize Firebase
export const initializeFirebase = () => {

    const config = isDev() ? stagingFirebaseConfig : productionFirebaseConfig
    const key = isDev() ? stagingPublicKey : productionPublicKey

    const app = initializeApp(config);
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app);

    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    getToken(messaging, {vapidKey: key}).then((currentToken) => {
        if (currentToken) {
            setStorageValue('firebaseToken', currentToken);
        } else {
            console.log('No registration token available.');
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);

        // show message in UI.
        const {title, body} = payload.notification
        if (title || body) {
            alert(`${title} - ${body}`)
        }
    })
}

export const requestNotificationPermission = () => {
    if (!("Notification" in window)) {
        console.log("Does not support notification permissions.");
    } else if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        console.log("Notification permission is already granted.");
    } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                console.log("Notification permission is granted.")
            }
        });
    }
}

