import Container from '@material-ui/core/Container'
import React, {useState} from 'react'
import {Button, CircularProgress, Snackbar, Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    getRounds,
    getSelectedPairing,
    getAllArbitrators,
    getAvailableSchools,
    getAllTeams
} from "../../../../reducers/adminSelectors";
import {savePairing} from "../../../../actions/adminActions";
import {noop, map, compact, includes, find} from "lodash/fp";
import Grid from "@material-ui/core/Grid";
import {utcToZonedTime, format} from "date-fns-tz";
import Select from "../../../common/Select";
import TextInput from "../../../common/TextInput";
import {Alert, Checkbox} from "@mui/material";
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Link, useHistory} from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {buildArbitratorOptionList} from "../../../../utils/arbitratorUtils";
import {ArbitratorConflictDropDown} from "../../../ArbitratorConflictDropDown";
import TeamName from 'components/team/TeamName';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const EditPairing = ({selectedPairingId = 0, getSelectedPairing = noop, selectedPairing = {},getAllArbitrators= noop,
                         selectedRound = {},
                         getRounds = noop,
                         allArbitrators = [],
                         savePairing = noop ,
                         pairingSaveError,
                         teams = [],
                        getAllTeams = noop}) => {
    const history = useHistory()

    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSaving, setIsSaving] = React.useState(false)
    const [saved, setSaved] = useState(false);

    const [newPairing,setNewPairing] = useState({})
    const onSave = async e => {
        e.preventDefault()
        setIsSaving(true)
        const result = await savePairing(newPairing)
        setIsSaving(false)
        if (result) {
            setSaved(true)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };


    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                await getRounds();
                const result = await getSelectedPairing(selectedPairingId)
                await getAllArbitrators()
                await getAllTeams()

                setNewPairing({
                    id: selectedPairingId,
                    claimantId: result.claimant?.id === undefined ? null : result.claimant.id,
                    claimant: result.claimant,
                    respondentId: result.respondent?.id === undefined ? null : result.respondent.id,
                    respondent: result.respondent,
                    claimantPractice: result.claimantPractice,
                    respondentPractice: result.respondentPractice,
                    pairingDateString: format(utcToZonedTime(result.pairingTime, 'Asia/Hong_Kong'),'MM/dd/yyyy'),
                    pairingTimeString: format(utcToZonedTime(result.pairingTime, 'Asia/Hong_Kong'),'hh:mm aaa'),
                    hearingLink: result.hearingLink,
                    room: result.room,
                    chairArbitratorId: result.chairArbitratorId ?? -1,
                    secondArbitratorId: result.secondArbitratorId ?? -1,
                    thirdArbitratorId: result.thirdArbitratorId ?? -1,
                    firstReserveArbitratorId: result.firstReserveArbitratorId ?? -1,
                    secondReserveArbitratorId: result.secondReserveArbitratorId ?? -1,
                    thirdReserveArbitratorId: result.thirdReserveArbitratorId ?? -1,

                })
                setIsLoading(false)
            }catch(e){
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const availableSchools = map(t =>{ return { id: t.id, name: t.school} },teams)

    const arbitratorNames = buildArbitratorOptionList(selectedPairing.claimant,selectedPairing.respondent,allArbitrators)

    return (
        <Container maxWidth='xl' className={classes.root}>
            { isLoading &&
                <CircularProgress />
            }
            {!isLoading &&
                <>
                    <form onSubmit={onSave}>
                        <Button style={{ margin: '0 0 2rem 0' }} variant='text' component={Link} onClick={() => history.go(-1)} >
                            <ArrowBackIosIcon /> Back to Oral  Arguments
                        </Button>
                        <Typography variant='h4'>Edit Schedule</Typography>
                        <Typography style={{marginBottom: '1rem'}}> <strong><TeamName team={selectedPairing.claimant} adminUse={true} /> vs. <TeamName team={selectedPairing.respondent} adminUse={true} /></strong> ({format(utcToZonedTime(selectedPairing.pairingTime, 'Asia/Hong_Kong'), 'iii, dd MMMM hh:mm aaa')})</Typography>
                       
                        <Grid container style={{ flexGrow: 1, margin: '1rem 0' }} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Select name='claimant'
                                        onChange={e => setNewPairing({...newPairing, claimant: find(t => t.id === e.target.value,teams), claimantId: e.target.value})}
                                        value={newPairing.claimantId} label='Claimant' options={availableSchools}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox  checked={newPairing.claimantPractice}
                                                name='claimantPractice'
                                                onChange={() => setNewPairing({...newPairing, claimantPractice: !newPairing.claimantPractice})}
                                                color='primary' />
                                            }
                                            label='Claimant Practice?'
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Select name='respondent'
                                        onChange={e => setNewPairing({...newPairing, respondent: find(t => t.id === e.target.value,teams), respondentId: e.target.value})}
                                        value={newPairing.respondentId} label='Respondent' options={availableSchools}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox  checked={newPairing.respondentPractice}
                                            onChange={() => setNewPairing({...newPairing, respondentPractice: !newPairing.respondentPractice})}
                                            name='respondentPractice'
                                            color='primary' />
                                            }
                                            label='Respondent Practice?'
                                    />
                                </FormGroup>
                                
                            </Grid>
                            
                        
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Select name='pairingDates' required
                                                onChange={e => setNewPairing({...newPairing, pairingDateString: e.target.value})}
                                        value={newPairing.pairingDateString} label='Date' options={selectedRound.availableDates}/>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Select name='pairingTimes' required
                                                onChange={e => setNewPairing({...newPairing, pairingTimeString: e.target.value})}
                                        value={newPairing.pairingTimeString} label='Time' options={selectedRound.availableTimes}/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextInput name='hearingLink' notRequired
                                            onChange={e => setNewPairing({...newPairing, hearingLink: e.target.value})}
                                            value={newPairing.hearingLink} label='Virtual Link' />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextInput name='room' notRequired
                                               onChange={e => setNewPairing({...newPairing, room: e.target.value})}
                                               value={newPairing.room} label='Room' />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ArbitratorConflictDropDown
                                        name='chairArbitrator'
                                        onChange={e => setNewPairing({...newPairing, chairArbitratorId: e.target.value })}
                                        value={newPairing.chairArbitratorId}
                                        label='Arbitrator 1'
                                        arbitrators={allArbitrators}
                                        pairing={newPairing} />


                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ArbitratorConflictDropDown
                                        name='secondArbitrator'
                                        onChange={e => setNewPairing({...newPairing, secondArbitratorId: e.target.value })}
                                        value={newPairing.secondArbitratorId}
                                        label='Arbitrator 2'
                                        arbitrators={allArbitrators}
                                        pairing={newPairing}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ArbitratorConflictDropDown
                                        name='thirdArbitrator'
                                        onChange={e => setNewPairing({...newPairing, thirdArbitratorId: e.target.value })}
                                        value={newPairing.thirdArbitratorId}
                                        label='Arbitrator 3'
                                        arbitrators={allArbitrators}
                                        pairing={newPairing}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ArbitratorConflictDropDown
                                        name='firstReserveArbitrator'
                                        onChange={e => setNewPairing({...newPairing, firstReserveArbitratorId: e.target.value })}
                                        value={newPairing.firstReserveArbitratorId}
                                        label='First Reserve'
                                        arbitrators={allArbitrators}
                                        pairing={newPairing}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ArbitratorConflictDropDown
                                        name='secondReserveArbitrator'
                                        onChange={e => setNewPairing({...newPairing, secondReserveArbitratorId: e.target.value })}
                                        value={newPairing.secondReserveArbitratorId}
                                        label='Second Reserve'
                                        arbitrators={allArbitrators}
                                        pairing={newPairing}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ArbitratorConflictDropDown
                                        name='thirdReserveArbitrator'
                                        onChange={e => setNewPairing({...newPairing, thirdReserveArbitratorId: e.target.value })}
                                        value={newPairing.thirdReserveArbitratorId}
                                        label='Third Reserve'
                                        arbitrators={allArbitrators}
                                        pairing={newPairing}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} style={{display:'flex', alignItems: 'center'}}>
                                    <Button variant='contained'
                                            type='submit'
                                            color='primary'
                                            disabled={isSaving}>Submit</Button>
                                    {isSaving &&
                                        <CircularProgress size={30} style={{marginLeft: '2rem'}}/>
                                    }                        
                                </Grid>
                            </Grid>
                        </Grid>
                        {pairingSaveError &&
                            <Typography color='error'>{pairingSaveError}</Typography>
                        }
                        <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                            <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                                Pairing saved
                            </Alert>
                        </Snackbar>
                    </form>
                </>
            }
        </Container>
    )
}



EditPairing.propTypes = {
    selectedPairing: PropTypes.object,
    selectedRound: PropTypes.object,
    getSelectedPairing: PropTypes.func,
    allArbitrators : PropTypes.array,
    getRounds: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        selectedPairing: state.admin.selectedPairing,
        selectedRound: state.admin.selectedRound,
        teams: state.admin.teams,
        selectedPairingId: ownProps.match.params.pairingId,
        allArbitrators: state.admin.arbitrators,
        pairingSaveError: state.admin.pairingSaveError
    }),{
        getSelectedPairing: getSelectedPairing,
        getAllArbitrators: getAllArbitrators,
        getAllTeams: getAllTeams,
        savePairing: savePairing,
        getRounds: getRounds
    })( EditPairing)
