import makeAction from "./actionCreator";
import * as Actions from "./actionTypes/scoringActionTypes";
import * as api from "../api";

export const saveScores =  (pairingId,scoreData) => async (dispatch,getState) => {

    dispatch(makeAction(Actions.SAVE_SCORES_REQUESTED, {pairingId,scoreData}))

    try {
        const result = await api.saveDraftScores(pairingId,scoreData)
        dispatch(makeAction(Actions.SAVE_SCORES_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_SCORES_FAILED, {},e))
    }
}

export const getCompleteOralArgumentSchedule = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_REQUESTED))
    try {
        const result = await api.getCompleteOralArgumentSchedule()
        dispatch(makeAction(Actions.GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_FAILED, {},e))
    }
}

export const getDraftScore = (id) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_DRAFT_SCORE_REQUESTED))
    try {
        const result = await api.getDraftScore(id)
        dispatch(makeAction(Actions.GET_DRAFT_SCORE_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.GET_DRAFT_SCORE_FAILED, {},e))
    }
}