export const SAVE_SCORES_REQUESTED = 'SAVE_SCORES_REQUESTED'
export const SAVE_SCORES_SUCCEEDED = 'SAVE_SCORES_SUCCEEDED'
export const SAVE_SCORES_FAILED = 'SAVE_SCORES_FAILED'

export const GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_REQUESTED = 'GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_REQUESTED'
export const GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED = 'GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED'
export const GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_FAILED = 'GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_FAILED'


export const GET_DRAFT_SCORE_REQUESTED = 'GET_DRAFT_SCORES_REQUESTED'
export const GET_DRAFT_SCORE_SUCCEEDED = 'GET_DRAFT_SCORES_SUCCEEDED'
export const GET_DRAFT_SCORE_FAILED = 'GET_DRAFT_SCORES_FAILED'