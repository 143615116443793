import * as actionTypes from '../actions/actionTypes/arbitratorActionTypes'
import {combineReducers} from "redux";
import {without, get, filter} from "lodash/fp";
import { TEAM_ROLE_TYPE } from "../utils/constants";

const selectedArbitrator = (state = { }, action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ARBITRATOR_SUCCEEDED) {
        return payload
    }
    else if (type === actionTypes.SET_ARBITRATOR_SCHOOLS_TO_WATCH){
        return {
            ...state,
            schoolsToWatch: payload
        }
    }
    else if (type === actionTypes.SET_ARBITRATOR_AFFILIATED_SCHOOLS){
        return {
            ...state,
            affiliatedSchools: payload
        }
    }
    else if (type === actionTypes.SET_ARBITRATOR_ROUNDS_YOU_CAN_ARBITRATE){

        return {
            ...state,
            roundsYouCanArbitrate: payload
        }
    }
    else {
        return state
    }
}

const selectedArbitratorSchedule = (state = [], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ARBITRATOR_SUCCEEDED){
        return payload.schedule
    }
    if (type === actionTypes.ADD_ARBITRATOR_AVAILABILITY){
        return [payload, ...state]
    }
    else if (type === actionTypes.REMOVE_ARBITRATOR_AVAILABILITY){
        return without([payload],state)
    } else
        return state
}

const arbitratorAvailabilitySaveError = (state ='',action) => {
    const {type} = action
    if (type === actionTypes.SAVE_ARBITRATOR_AVAILABILITY_FAILED) {
        //TODO How much more info should we give?
        return 'Unable to save availability. Please try again'
    }
    if (type === actionTypes.SAVE_ARBITRATOR_AVAILABILITY_SUCCEEDED) {
        return ''
    } else {
        return state
    }
}

const claimantPairings = ( state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ARBITRATIOR_CLAIMANT_PAIRINGS_SUCCEEDED) {
        return payload
    } else {
        return state
    }
}

const respondentPairings = ( state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ARBITRATIOR_RESPONDENT_PAIRINGS_SUCCEEDED) {
        return payload
    } else {
        return state
    }
}

const arbitratorRankingsSaveError = (state ='',action) => {
    const {type} = action
    switch (type){
        case actionTypes.SAVE_ARBITRATOR_RANKINGS_FAILED:
            //TODO How much more info should we give?
            return 'Unable to save rankings. Please try again'
        case actionTypes.SAVE_ARBITRATOR_RANKINGS_SUCCEEDED:
            return ''
        default:
            return state
    }
}

const claimantRankings = (state =[], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ARBITRATOR_SUCCEEDED) {
        return filter(r => r.teamRole === TEAM_ROLE_TYPE.CLAIMANT, get('ArbitratorRankings',payload))
    }else
        return state
}

const respondentRankings = (state =[], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ARBITRATOR_SUCCEEDED) {
        return filter(r => r.teamRole === TEAM_ROLE_TYPE.RESPONDENT, get('ArbitratorRankings',payload))
    }else
        return state
}

const claimantJuryTeams = (state =[], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_CLAIMANT_JURY_TEAMS_SUCCEEDED) {
        return payload
    }else
        return state
}
const respondentJuryTeams = (state =[], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_RESPONDENT_JURY_TEAMS_SUCCEEDED) {
        return payload
    }else
        return state
}

const arbitratorProfileSaveError = (state = '',action) => {
    const {type} = action
    if (type === actionTypes.SAVE_ARBITRATOR_PROFILE_FAILED) {
        //TODO How much more info should we give?
        return 'Unable to save profile. Please try again'
    }else
        return state
}

const oralArgumentSchedule = (state = [],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED) {
        return payload
    }else{
        return state
    }
}

export default combineReducers({
    selectedArbitrator,
    selectedArbitratorSchedule,
    arbitratorAvailabilitySaveError,
    arbitratorProfileSaveError,
    claimantPairings,
    claimantRankings,
    respondentPairings,
    respondentRankings,
    arbitratorRankingsSaveError,
    claimantJuryTeams,
    respondentJuryTeams,
    oralArgumentSchedule
})
