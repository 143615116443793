import {Button, CircularProgress, FormGroup, Snackbar, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {filter, find, get, startCase,toLower, isEmpty, map, noop, size, without, xor} from "lodash/fp";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {Alert} from "@mui/material";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getSelectedArbitrator} from "../../reducers/arbitratorSelectors";
import * as actions from "../../actions/arbitratorActions";
import { toBase64} from "../team/UploadDocument/uploadUtils";
import { TEAM_ROLE_TYPE } from "../../utils/constants";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TeamName from "../team/TeamName";

const WrittenRankings = ({
    arbitratorRankings = [],
    arbitratorPairings,
    saveRankings = noop,
    uploadCriticalCommentsDocument = noop,
    arbitratorRankingsSaveError = '',
                             teamRoleType
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [documents, setDocuments] = useState([])
    const [rankings, setRankings] = useState([])
    const [showAlert,setShowAlert] = useState(false)

    const isTopThreeRanked = teamId => {
        const ranks = isRankingCompleted() ? arbitratorRankings : rankings

        const id = parseInt(teamId)

        const teamRank = find(r => r.teamId === id,ranks)
        return get('rank',teamRank) >=3
    }

    const canSave = () => {
        //Every team must have a ranking
        const ids = map(cp => cp.team.id, arbitratorPairings)
        const rankingIds = map(r => r.teamId,rankings)

        return !isEmpty(rankingIds) && xor(ids, rankingIds).length === 0
    }
    const addOrReplaceDocument = (teamId, file) => {

        const id = parseInt(teamId)
        const existingDoc = find(d => d.id === id,documents)
        if (existingDoc){
            const lessExistingDocument = without(filter(e => e.id === id,documents),documents)
            setDocuments([...lessExistingDocument,{id, file}])
        }else {
            setDocuments([...documents,{id, file}])
        }
    }

    const onSave = async (e, saveWithoutDoc = false) => {
        try {
            e.preventDefault()
            if ( size(documents) === 0 && saveWithoutDoc === false){
                //Show warning
                setShowAlert(true)
                return
            }
            setIsSaving(true)
            for (let i = 0 ; i < size(documents); i++){
                const document = documents[i]
                const base64File = await toBase64(document.file)
                await uploadCriticalCommentsDocument(teamRoleType,document.id,base64File.body)
            }
            const didSave = await saveRankings(rankings, teamRoleType)

            if (didSave) {
                setSaved(true)
            }
        } finally {
            setIsSaving(false)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const isPossibleBestThree = teamId => {
        const ranks = isRankingCompleted() ? arbitratorRankings : rankings

        const id = parseInt(teamId)
        const team = find(r => r.teamId === id,ranks)

        return team.possibleBestThree === true
    }

    const onPossibleBestThreeChanged = (e,teamId) => {
        const id = parseInt(teamId)
        const team = find(r => r.teamId === id, rankings)
        team.possibleBestThree = e.target.checked

        const newRankings = without(filter(r => r.teamId === teamId, rankings), rankings)
        setRankings([...newRankings, team])
    }

    const rankingValues = [
        { value: 0, name : "0"},
        { value: 1, name : "1"},
        { value: 2, name : "2"},
        { value: 3, name : "3"},
        { value: 4, name : "4"},
        { value: 5, name : "5"}  
    ]


    const teamFileRefs = map( p => ({ teamId: p.team.id, ref: React.createRef()}),arbitratorPairings)

    const onRankingChanged = (e, teamId) => {
        const val = parseInt(e.target.value)
        if (e.target.checked) {
            setRankings([...rankings, {rank: val, teamId}])
        }else{
            const newRankings = without(filter(r => r.teamId === teamId && r.rank === val,rankings),rankings)
            setRankings(newRankings)
        }
    }

    const isRanked = (teamId,rankValue) =>{
        const ranks = isRankingCompleted() ? arbitratorRankings : rankings

        const val = parseInt(rankValue)
        const hasRanking = find(r => r.teamId === teamId && r.rank === val,ranks)
        return hasRanking !== undefined
    }

    const isBlockedFromRanking = (teamId,rankValue) =>{
        const val = parseInt(rankValue)
        const teamRanking = find(r => r.teamId === teamId,rankings)
        const rankedValues = map(r => r.rank,rankings)
        if (teamRanking === undefined && !rankedValues.includes(val))
            return false

        if (teamRanking !== undefined && teamRanking.rank !== val)
            return true

        return rankedValues.includes(val) && get('rank',teamRanking) !== val
    }

    const isRankingCompleted = ()  => {
        return !isEmpty(arbitratorRankings)
    }
    return (
    <form onSubmit={onSave}>
        <Grid style={{padding: 0}}>
            {map(p => {
                return (
                    <Grid item key={p.team.id} className='arbitrator-pairing'>
                        <Typography className='arbitrator-pairing-school'>
                            <a href={p.url}><TeamName team={p.team}/> memorandum.pdf</a>
                        </Typography>

                        <FormGroup row style={{alignItems: 'center', marginBottom: '6px'}}>
                            <label className='label-ranking'>Ranking:</label>
                            {map(ranking =>
                                    <FormControlLabel
                                        key={`${p.team.id}-${ranking.value}`}
                                        className='arbitrator-pairings-control-label'
                                        disabled={isBlockedFromRanking(p.team.id, ranking.value)}
                                        control={
                                            <div style={{position: 'relative'}}>
                                                <Checkbox
                                                    checked={isRanked(p.team.id, ranking.value)}
                                                    disabled={isBlockedFromRanking(p.team.id, ranking.value) || isRankingCompleted()}
                                                    onChange={e => onRankingChanged(e, p.team.id)}
                                                    value={ranking.value}
                                                    color='primary'
                                                />
                                                {ranking.value === 0 &&
                                                    <Typography style={{
                                                        margin: '0',
                                                        position: 'absolute',
                                                        top: '28px',
                                                        left: '0',
                                                        right: '0',
                                                        textAlign: 'center',
                                                        fontSize: '11px',
                                                    }}
                                                    >worst</Typography>
                                                }
                                                {ranking.value === 5 &&
                                                    <Typography  style={{
                                                        margin: '0',
                                                        position: 'absolute',
                                                        top: '28px',
                                                        left: '0',
                                                        right: '0',
                                                        textAlign: 'center',
                                                        fontSize: '11px',
                                                    }}
                                                    >best</Typography>
                                                }
                                            </div>
                                        }
                                        label={ranking.name}
                                    />
                                , rankingValues)}
                            {isTopThreeRanked(p.team.id) &&
                            <FormControlLabel
                                key={`${p.team.id}-bestthree`}
                                label="Possible best 3 in entire Moot?"
                                control={
                                    <div style={{position: 'relative'}}>
                                        <Checkbox
                                            checked={isPossibleBestThree(p.team.id)}
                                            disabled={isRankingCompleted()}
                                            onChange={e => onPossibleBestThreeChanged(e, p.team.id)}
                                            value={true}
                                            color='primary'
                                        />
                                    </div>
                                }
                            />
                            }
                        </FormGroup>
                        {!isRankingCompleted() &&
                        <>
                            <span>Upload Critique Form: </span>
                            <input type="file" className='arbitrator-pairings-upload-file'
                                   ref={get('ref', find(tr => tr.teamId === p.team.id, teamFileRefs))}
                                   accept="application/pdf"
                                   onChange={e => addOrReplaceDocument(p.team.id, e.target.files[0])}/>
                            <Dialog
                                open={showAlert}
                                onClose={() => setShowAlert(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Save without uploading critique forms?
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                    Please make sure that you have successfully uploaded your critique forms BEFORE submitting your rankings. Are you sure you wish to proceed?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions style={{padding: "16px 24px"}}>
                                    <Button onClick={() => {
                                        setShowAlert(false)
                                    }}>Go back</Button>
                                    <Button onClick={async (e) => {
                                        setShowAlert(false)
                                        await onSave(e, true)
                                    }} autoFocus
                                    variant='contained'
                                    color='primary'>
                                        Proceed
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                        }
                        {/*</Box>*/}
                    </Grid>)
            }, arbitratorPairings)
            }
        </Grid>
        <Grid style={{padding: 0}}>
        {!isRankingCompleted() &&
        <Grid item>
            <Typography style={{margin: '2rem 0 0'}}>Submission of rankings and critique forms are FINAL. Please remember that critique forms must be uploaded BEFORE rankings are submitted. Once you submit your rankings, no further actions can be done in relation to {startCase(toLower(teamRoleType))}'s memoranda ranking. Please ensure that your rankings for the {toLower(teamRoleType)}’s memoranda are submitted <strong><u>no later than {teamRoleType === 'CLAIMANT' ? '14 January 2025' : '2 March 2025'}</u></strong>.
            </Typography>
        </Grid>   
        }
        <Grid item style={{marginTop: '2rem', display: 'flex'}}>
            {!isRankingCompleted() &&
            <>
                <Button variant='contained'
                        type='submit'
                        color='primary'
                        disabled={isSaving || !canSave()}
                        >Save</Button>
            </>
            }
            {isRankingCompleted() &&
            <Typography variant="body1">Rankings have been submitted.</Typography>
            }
            {isSaving &&
            <CircularProgress size={30} style={{marginLeft: '2rem'}}/>
            }
            {arbitratorRankingsSaveError &&
            <Typography color='error' style={{marginLeft: '2rem'}}>{arbitratorRankingsSaveError}</Typography>
            }
        </Grid>
        <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                Rankings saved
            </Alert>
        </Snackbar>

        </Grid>
    </form>
    )
}


WrittenRankings.propTypes = {
    arbitratorPairings: PropTypes.array,
    saveRankings: PropTypes.func,
    uploadCriticalCommentsDocument: PropTypes.func,
    teamRoleType: PropTypes.oneOf([TEAM_ROLE_TYPE.CLAIMANT,TEAM_ROLE_TYPE.RESPONDENT])
}

export default connect(
    (state, ownProps) => ({
        teamRoleType : ownProps.teamRoleType,
        arbitratorRankingsSaveError : state.arbitrator.arbitratorRankingsSaveError
    }),
    {
        getSelectedArbitrator: getSelectedArbitrator,
        saveRankings: actions.saveRankings,
        uploadCriticalCommentsDocument: actions.uploadCriticalCommentsDocument
    }
)(WrittenRankings)