import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Button, FormControl, Radio, RadioGroup, Snackbar} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextInput from "../../common/TextInput";
import {connect} from "react-redux";
import * as actions from "../../../actions/adminActions";
import _, {isEmpty} from 'lodash/fp'
import {Alert} from "@mui/material";
import Select from "../../common/Select";
import {getCompleteOralArgumentSchedule} from "../../../reducers/scoringSelectors";
import {getMootRoundDateOptions, getMootRoundTimeOptions} from "../../../utils/dateUtils";
import {getCurrentMoot} from "../../../reducers/mootSelectors";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const NotificationsDashboard = ({
                                    oralArgumentSchedule,
                                    sendNotification = _.noop,
                                    getCurrentMoot = _.noop
                                }) => {

    const classes = useStyles()

    const [notificationData, setNotificationData] = useState({})
    const [isSending, setIsSending] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        async function getData() {
            await getCurrentMoot()
        }

        getData()
    }, [])

    const onSendNotification = async (e) => {
        e.preventDefault();
        try {
            setIsSending(true)
            setSnackbarSeverity('info')
            setSnackbarMessage('sending notification')
            setShowSnackbar(true)
            await sendNotification(notificationData)
            setSnackbarSeverity('success')
            setSnackbarMessage('notification sent')
            setNotificationData({title: '', message: ''})
        } finally {
            setIsSending(false)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false)
    };


    const getLabel = value => {
        if (value === 'ArbitratorsAndTeams') {
            return 'Arbitrators & Teams'
        }
        if (value === 'EmailAndPush') {
            return 'Email & Push'
        }
        return value
    }


    const ClearButton = ({field}) => {
        return (<Button variant='text' onClick={() => {
                setNotificationData({
                    ...notificationData,
                    [field]: undefined
                })
            }}>Clear</Button>
        )
    }
    return (<Container style={{paddingBottom: '10rem'}}>
            <>
                <Typography variant='h4' component='h1' style={{marginBottom: '1rem'}}>Notifications</Typography>
                <Grid item xs={12} style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                    <Typography variant='h5' component='p'>Send a notification</Typography>
                    <Typography variant='subtitle2' component='p'>Notifications will go out to only accounts that have
                        been "accepted" for this current moot.</Typography>
                    <Grid item xs={12} style={{marginTop: "1rem", background: "#F0F0F0", marginBottom: "1rem"}}>
                        <form onSubmit={onSendNotification}>
                            <Grid style={{padding: "1rem"}}>
                                <Typography variant="h6">Send To:</Typography>
                                <Grid item xs={12}>
                                    <FormControl component='fieldset'>
                                        <RadioGroup name="sendTo">
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color='primary'
                                                        checked={
                                                            notificationData.sendTo ===
                                                            'ArbitratorsAndTeams'
                                                        }
                                                        onChange={e => {
                                                            setNotificationData({
                                                                ...notificationData,
                                                                sendTo: e.target.value
                                                            })
                                                        }}
                                                        value='ArbitratorsAndTeams'
                                                        required
                                                    />
                                                }
                                                label='Arbitrators & Teams'
                                            />
                                            <Grid>
                                                <FormControlLabel style={{width: '100%'}}
                                                    control={
                                                        <Radio
                                                            color='primary'
                                                            checked={
                                                                notificationData.sendTo ===
                                                                'Arbitrators'
                                                            }
                                                            onChange={e => {
                                                                setNotificationData({
                                                                    ...notificationData,
                                                                    sendTo: e.target.value
                                                                })
                                                            }}
                                                            value='Arbitrators'
                                                            required
                                                        />
                                                    }
                                                    label='Arbitrators'
                                                />
                                                {notificationData.sendTo === 'Arbitrators' &&
                                                    <Grid container item xs={12}>
                                                        <Grid item xs={12}>
                                                            <Typography variant='subtitle2' component='p'>Filter arbitrators by:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} style={{display: 'flex', paddingRight: '20px'}}>
                                                            <Select
                                                                onChange={(e) => setNotificationData({
                                                                    ...notificationData,
                                                                    participation: e.target.value,
                                                                })}
                                                                label='Participation'
                                                                value={notificationData.participation}
                                                                options={['Oral','Written']}
                                                                helperText='This field is optional'
                                                            />
                                                            <ClearButton field='participation'/>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} style={{display: 'flex', paddingRight: '20px'}}>
                                                            <Select
                                                                onChange={(e) => setNotificationData({
                                                                    ...notificationData,
                                                                    date: e.target.value,
                                                                })}
                                                                disabled={notificationData.participation !== 'Oral'}
                                                                label='Round Date'
                                                                value={notificationData.date}
                                                                options={getMootRoundDateOptions(oralArgumentSchedule,true,'utc')}
                                                                helperText='This field is optional'
                                                            />
                                                            <ClearButton field='date'/>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} style={{display: 'flex', paddingRight: '20px'}}>
                                                            <Select
                                                                onChange={(e) => setNotificationData({
                                                                    ...notificationData,
                                                                    time: e.target.value,
                                                                })}
                                                                label='Round Time'
                                                                disabled={!notificationData.date}
                                                                value={notificationData.time}
                                                                options={getMootRoundTimeOptions(notificationData?.date, oralArgumentSchedule,'utc')}
                                                                helperText='This field is optional'
                                                            />
                                                            <ClearButton field='time'/>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color='primary'
                                                        checked={
                                                            notificationData.sendTo ===
                                                            'Teams'
                                                        }
                                                        onChange={e => {
                                                            setNotificationData({
                                                                ...notificationData,
                                                                sendTo: e.target.value
                                                            })
                                                        }}
                                                        value='Teams'
                                                        required
                                                    />
                                                }
                                                label='Teams'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid style={{padding: "1rem"}}>
                                <Typography variant="h6">Notification Type:</Typography>
                                <Grid item xs={12}>
                                    <FormControl component='fieldset'>
                                        <RadioGroup name="notificationType">
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color='primary'
                                                        checked={
                                                            notificationData.type ===
                                                            'EmailAndPush'
                                                        }
                                                        onChange={e => {
                                                            setNotificationData({
                                                                ...notificationData,
                                                                type: e.target.value
                                                            })
                                                        }}
                                                        value='EmailAndPush'
                                                        required
                                                    />
                                                }
                                                label='Email & Push'
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color='primary'
                                                        checked={
                                                            notificationData.type ===
                                                            'Email'
                                                        }
                                                        onChange={e => {
                                                            setNotificationData({
                                                                ...notificationData,
                                                                type: e.target.value
                                                            })
                                                        }}
                                                        value='Email'
                                                        required
                                                    />
                                                }
                                                label='Email'
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color='primary'
                                                        checked={
                                                            notificationData.type === 'Push'
                                                        }
                                                        onChange={e => {
                                                            setNotificationData({
                                                                ...notificationData,
                                                                type: e.target.value
                                                            })
                                                        }}
                                                        value='Push'
                                                        required
                                                    />
                                                }
                                                label='Push'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid style={{padding: "1rem"}}>
                                <TextInput required label='Subject/Title'
                                           maxLength={200}
                                           value={notificationData.title}
                                           onChange={e => setNotificationData({
                                               ...notificationData,
                                               title: e.target.value
                                           })}/>

                            </Grid>
                            <Grid style={{padding: "1rem"}}>
                                <TextInput required minRows={4} multiline label='Message'
                                           value={notificationData.message}
                                           maxLength={(notificationData.type === 'Push' || notificationData.type === 'EmailAndPush') ? 250 : 999999}
                                           onChange={e => setNotificationData({
                                               ...notificationData,
                                               message: e.target.value
                                           })}/>

                            </Grid>
                            <h4>Preview Notification</h4>

                            <Grid style={{padding: "1rem", marginBottom: "1rem"}}>
                                <Typography><span
                                    style={{fontWeight: 'bold'}}>To:</span> {getLabel(notificationData.sendTo)}
                                </Typography>
                                <Typography><span
                                    style={{fontWeight: 'bold'}}>Type:</span> {getLabel(notificationData.type)}
                                </Typography>
                                <Typography><span
                                    style={{fontWeight: 'bold'}}>Subject/Title:</span> {notificationData.title}
                                </Typography>
                                <Typography style={{whiteSpace: 'pre-line'}}><span
                                    style={{fontWeight: 'bold'}}>Message:</span> {notificationData.message}</Typography>
                            </Grid>
                            <Button variant='contained'
                                    type='submit'
                                    color="primary"
                                    disabled={isSending}>Send</Button>
                            <Snackbar open={showSnackbar} onClose={handleClose} autoHideDuration={15000}>
                                <Alert severity={snackbarSeverity} onClose={handleClose} sx={{width: '100%'}}>
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>
                        </form>
                    </Grid>
                </Grid>
            </>
        </Container>
    )
}


export default connect(
    (state, ownProps) => ({
        router: state.router,
        currentMoot: state.moot.currentMoot,
        user: state.user,
        oralArgumentSchedule: state.moot.currentMoot.schedule,
    }),
    {
        sendNotification: actions.sendNotification,
        getCurrentMoot: getCurrentMoot,
    }
)(NotificationsDashboard)