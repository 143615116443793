import React from 'react'
import Container from '@material-ui/core/Container'
import { NavLink } from 'react-router-dom'
import { Box, Button, TextField, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import {forgotPassword} from "../../utils/cognitoHelpers";

const PasswordReset = () => {
  const [email, setEmail] = React.useState('')
  const history = useHistory()

  const handleForgotPassword = async () => {
      try {
          await forgotPassword(email);
          history.push(`/login/password-reset/sent?email=${email}`)
      } catch (e) {
          console.log(e);
      }
  }

  return (
      <Container maxWidth='sm'>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4'>Password Reset</Typography>
          <TextField
            value={email}
            label='Email'
            onChange={e => setEmail(e.target.value)}
          />
          <Box style={{ textAlign: 'center', margin: '2rem 0' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Button onClick={handleForgotPassword} variant='contained'>
                Reset your Password
              </Button>
              <NavLink to='/login' className={'color-dark'}>Sign in</NavLink>
            </Box>
          </Box>
        </Box>
      </Container>
  )
}

export default PasswordReset
