import React from 'react'

export const teamNameTextOnly = (team) => {
    if(team?.hideTeamName === true) {
        return `TEAM ID ${team.id}`;
    } else {
        return team?.school;
    }
}

const TeamName = ({team, adminUse = false}) => {
    return (
        <>
        {
            team?.hideTeamName === false && adminUse === true &&
            <span>{team.school}</span>
        }
        {
            team?.hideTeamName === true && adminUse === true &&
            <span><span style={{color: '#909090'}}>{team.school}</span> (TEAM ID {team.id})</span>
        }
        {
            team?.hideTeamName === true && adminUse === false &&
                <span>TEAM ID {team.id}</span>
        }       
        {
            team?.hideTeamName === false && adminUse === false &&
            <span>{team.school}</span>
        }
        </>
    )
}

export default TeamName