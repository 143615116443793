import { Grid } from "@material-ui/core";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GavelIcon from '@mui/icons-material/Gavel';
import InventoryIcon from '@mui/icons-material/Inventory';
import PlaceIcon from '@mui/icons-material/Place';
import MapIcon from '@mui/icons-material/Map';
import CustomButton from "components/common/CustomButton";
import { makeStyles } from "@material-ui/core/styles";


function DashboardMenu({ role }) {

    const useStyles = makeStyles(() => ({
        dashboardMenu: {
            display:'flex',
            flexWrap:'wrap', 
            gap:'20px',          
            "@media (max-width:1200px)": {
                display:'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                gap:'10px',              
            },
            "@media (max-width:820px)": {
                display:'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap:'10px',              
            },
            "@media (max-width:600px)": {
                display:'grid',
                gridTemplateColumns: '1fr 1fr',
                gap:'10px',              
            },
        },        
    }))

    const classes = useStyles() 

   
   return (
    <Grid container item xs={12}>
        <Grid item xs={12} className={classes.dashboardMenu}>
            <CustomButton startIcon={<RecordVoiceOverIcon style={{ fontSize: '32px' }} />} to={role === "TEAM" ? "/team/oral-arguments" : "/arbitrator/oral-arguments"}>Oral <br></br>Arguments</CustomButton>
            <CustomButton startIcon={<PlaceIcon style={{ fontSize: '32px' }} />} href="https://maps.app.goo.gl/ER9VDNCwooSCozun6" target="_blank" isExternal={true}>Locate <br></br>Justice Place</CustomButton> 
            <CustomButton startIcon={<MapIcon style={{ fontSize: '32px' }} />} href="https://cisgmoot.org/map-of-hearing-rooms" target="_blank" isExternal={true}>Map of <br></br>Hearing <br></br>Rooms</CustomButton> 
            {role === "ARBITRATOR" &&
            <>
            {/*<CustomButton startIcon={<GavelIcon style={{ fontSize: '32px' }} />} to="/arbitrator/oral-arguments">Score <br></br>Sheet</CustomButton>*/}
            <CustomButton startIcon={<InventoryIcon style={{ fontSize: '32px' }} />} href="https://cisgmoot.org/arbitrator-bundle/" target="_blank" isExternal={true}>Arbitrator <br></br>Bundle</CustomButton> 
            </>
            }
            <CustomButton startIcon={<CalendarMonthIcon style={{ fontSize: '32px' }} />} href="https://cisgmoot.org/events/" target="_blank" isExternal={true}>Schedule <br></br>of Events</CustomButton>
            <CustomButton startIcon={'FaqIcon'} href="https://cisgmoot.org/vis-east-moot/" target="_blank" isExternal={true}>Frequently <br></br>Asked <br></br>Questions</CustomButton>                           
        </Grid>
    </Grid>
   )
}

export default DashboardMenu;