import * as actions from '../actions/mootActions'
import _ from "lodash/fp";
import {formatInTimeZone, hongKongTimeFromUTC} from "../utils/dateUtils";

export const getCurrentMoot = (hardRefresh = false) => async (dispatch,getState) => {
    let currentMoot = _.get('moot.currentMoot',getState())
    if (hardRefresh || _.isEmpty(currentMoot)){
        await (actions.getAllMoots())(dispatch,getState)
        currentMoot = _.get('moot.currentMoot',getState())
    }
    return currentMoot
}

export const getAllMoots = () =>  async (dispatch,getState) => {
    let allMoots = _.get('moot.allMoots',getState())
    if (_.isEmpty(allMoots)){
        await (actions.getAllMoots())(dispatch,getState)
        allMoots = _.get('moot.allMoots',getState())
    }
    return allMoots
}


export const getSelectedMoot = id => async (dispatch, getState) => {
    const existing = _.get('moot.getSelectedMoot',getState())
    if (_.isEmpty(existing) || existing.id !== Number.parseInt(id)){
        const allMoots = await getAllMoots()(dispatch,getState)
        const moot = _.find(m => m.id === Number.parseInt(id),allMoots)
        await actions.selectMoot(moot)(dispatch,getState)
        return moot
    }
    return existing
}

export const getIsArbitratorRegistrationOpen = () => async (dispatch, getState) => {
    let  isArbitratorRegistrationOpen = _.get('moot.currentMoot.isArbitratorRegistrationOpen',getState())
    if (_.isEmpty(isArbitratorRegistrationOpen)){
        await (actions.getAllMoots())(dispatch,getState)
        isArbitratorRegistrationOpen = _.get('moot.currentMoot.isArbitratorRegistrationOpen',getState())
    }
    return isArbitratorRegistrationOpen
}

export const getIsDocumentSubmissionOpen = () => async (dispatch, getState) => {
    let  isTeamDocumentSubmissionOpen = _.get('moot.currentMoot.isTeamDocumentSubmissionOpen',getState())
    if (_.isEmpty(isTeamDocumentSubmissionOpen)){
        await (actions.getAllMoots())(dispatch,getState)
        isTeamDocumentSubmissionOpen = _.get('moot.currentMoot.isTeamDocumentSubmissionOpen',getState())
    }
    return isTeamDocumentSubmissionOpen
}

export const getIsTeamMemberOpen = () => async (dispatch, getState) => {
    let  isTeamMemberOpen = _.get('moot.currentMoot.isTeamMemberOpen',getState())
    if (_.isEmpty(isTeamMemberOpen)){
        await (actions.getAllMoots())(dispatch,getState)
        isTeamMemberOpen = _.get('moot.currentMoot.isTeamMemberOpen',getState())
    }
    return isTeamMemberOpen
}


export const getIsClarificationOpen = () => async (dispatch, getState) => {
    let  isClarificationOpen = _.get('moot.currentMoot.isClarificationOpen',getState())
    if (_.isEmpty(isClarificationOpen)){
        await (actions.getAllMoots())(dispatch,getState)
        isClarificationOpen = _.get('moot.currentMoot.isClarificationOpen',getState())
    }
    return isClarificationOpen
}


export const getIsTeamRegistrationOpen = () => async (dispatch, getState) => {
    let  isTeamRegistrationOpen = _.get('moot.currentMoot.isTeamRegistrationOpen',getState())
    if (_.isEmpty(isTeamRegistrationOpen)){
        await (actions.getAllMoots())(dispatch,getState)
        isTeamRegistrationOpen = _.get('moot.currentMoot.isTeamRegistrationOpen',getState())
    }
    return isTeamRegistrationOpen
}

export const getRoundTimesInHKForCurrentMoot = () =>  async (dispatch, getState) => {
    let rounds = _.get('moot.currentMoot.MootRound',getState())
    if (_.isEmpty(rounds)) {
        await (actions.getAllMoots())(dispatch, getState)
        rounds = _.get('moot.currentMoot.MootRound',getState())
    }
    return _.map(r => formatInTimeZone(r.date,'hh:mm aaa',"Asia/Hong_Kong") ,rounds)
}
