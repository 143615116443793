import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import { saveArbitratorRegistrationData } from 'actions/registrationActions'
import { getSelectedArbitrator } from 'reducers/arbitratorSelectors'

import {
    Grid, Container, Typography, Button, CircularProgress,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core'
import {every, isEmpty, noop} from "lodash/fp";
import {defaultArbitrator} from "./registrationUtils";
import TextField from "../common/TextInput";
import {useHistory} from "react-router-dom";
import Select from "../common/Select";
import {GENDERS, NATIONALITIES} from "../../utils/constants";
import {getAllJurisdictions} from "../../reducers/globalSelectors";
import {Autocomplete} from "@mui/material";
import {TextField as MUITextField} from "@material-ui/core";
import {getUser} from "../../actions/userActions";
import { saveAvailability} from "../../actions/arbitratorActions";
import ImageResize from "../common/ImageResize";
import { DesktopDatePicker , LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ArbitratorAvailability from "../arbitrator/Availability";
import {isNotEmpty} from "../../utils/funcUtils";
import {getCurrentMoot,getIsArbitratorRegistrationOpen} from "../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";
import {shouldOverride} from "../helpers";
import { format } from 'date-fns'
import {utcToZonedTime} from "date-fns-tz";
import Unauthorized from "../Unauthorized";

const ArbitratorRegistration = ({
                                    router,
                                    existingArbitratorData,
                                    jurisdictions = [],
                                    user,
                                    getSelectedArbitrator = noop,
                                    saveArbitratorRegistrationData = noop,
                                    getAllJurisdictions = noop,
                                    getUser = noop,
                                    getIsArbitratorRegistrationOpen = noop,
                                    isArbitratorRegistrationOpen = {},
                                    saveAvailability = noop,
                                    selectedArbitratorSchedule = [],
                                    currentMoot,
                                    getCurrentMoot = noop}) => {
    const history = useHistory()

    const [error,setError] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const [pending,setPending] = useState(false)
    const [currentStep,setCurrentStep] = useState(1)
    const [hasOralAvailabilityError,setHasOralAvailabilityError] = useState(false)
    const [hasWrittenAvailabilityError,setHasWrittenAvailabilityError] = useState(false)

    const defaultArbitratorData = !isEmpty(existingArbitratorData) ? existingArbitratorData: defaultArbitrator
    const [arbitratorData, setArbitratorData] = useState(defaultArbitratorData)
    const [wordCount, setWordCount] = useState(0)

    const overrideCode = router.location.query.override


    useEffect(() => {
        const asyncFetchData = async () => {
            setIsLoading(true)
            try{
                await getIsArbitratorRegistrationOpen()
                const result = await getSelectedArbitrator()
                await getAllJurisdictions()
                if (!isEmpty(result)) {
                    setArbitratorData(result)
                    countWords(result.bio)
                }
                await getCurrentMoot()
            }catch(e){
            }
            setIsLoading(false)
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const goBack = () => {
        setCurrentStep(currentStep - 1)
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
    }
    const goForward = () => {
        setCurrentStep(currentStep +1)
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
    }

    const save = async e => {
        e.preventDefault()
        try {
            setPending(true)
            setError('')
            const result = await saveArbitratorRegistrationData(arbitratorData,overrideCode)
            if (result === null)
                throw new Error("data not saved")

            if (isNotEmpty(selectedArbitratorSchedule) && currentStep === 3){
                const result = await saveAvailability()
                if (result === null)
                    throw new Error("data not saved")
            }
            await getSelectedArbitrator(true)
            await getUser()
            setPending(false)
            if (currentStep < 4) {
                goForward()
            }else if (currentStep === 4){
                history.replace('/arbitrator')
            }

        }catch(e){
            setError('Error saving registration. Please try again')
            setPending(false)
        }
    }

    const countWords = (count) => {
        if (count.length === 0) {
            setWordCount(0)
        } else {
            count = count.replace(/(^\s*)|(\s*$)/gi,"");
            count = count.replace(/[ ]{2,}/gi," ");
            count = count.replace(/\n /,"\n"); 
            setWordCount(count.split(' ').length)
        }
    }

    const nextMootArbitratorRegistrationYear = (currentArbitratorRegistrationOpenDate) => {
        const nextYear = new Date(currentArbitratorRegistrationOpenDate) 
        return nextYear.setFullYear(nextYear.getFullYear() + 1);
    }
    const isAllowed = () => {
        if (user){
            return every(u => u.isStudent !== true,user.TeamMember)
        }
    }

    return (
        <Container style={{ paddingBottom: '10rem' }}>
            { isLoading &&
                <CircularProgress size={35} />
            }
            { !isLoading && !isAllowed() &&
                <Typography>This account cannot be associated with an Arbitrator account</Typography>
            }
            { !isLoading && isAllowed() && (isArbitratorRegistrationOpen.notOpenYet === true &&  !shouldOverride(overrideCode,currentMoot)) &&
                <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                    <Grid item xs={12}>
                    <Typography component='h1' variant='h5'>Registration for Arbitrators will open on {format(utcToZonedTime(currentMoot?.arbitratorRegistrationOpenDate, 'Asia/Hong_Kong'), 'eeee, dd MMMM yyyy p')} (HKT)</Typography>                        
                    </Grid>
                </Grid>
            }
            { !isLoading && isAllowed() &&(isArbitratorRegistrationOpen.closed === true &&  !shouldOverride(overrideCode,currentMoot)) &&
                <Grid container style={{flexGrow: 1, padding: '20px'}} spacing={2}>
                    <Grid item xs={12}>
                        <Typography component='h1' variant='h5'>Registration for Arbitrators for the {ordinal(currentMoot?.mootNumber + 1)} Vis East will open in October {format(utcToZonedTime(nextMootArbitratorRegistrationYear(currentMoot.arbitratorRegistrationOpenDate),'Asia/Hong_Kong'), 'yyyy')}!</Typography>
                    </Grid>
                </Grid>
            }
            {!isLoading && isAllowed() && ((isArbitratorRegistrationOpen.notOpenYet === false && isArbitratorRegistrationOpen.closed === false) || shouldOverride(overrideCode,currentMoot)) &&
                <form onSubmit={save}>
                    <Grid container
                          style={{flexGrow: 1, padding: '20px'}}
                          spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='h4'>Arbitrator Registration Step {currentStep} of 4</Typography>
                        </Grid>
                        {/*STEP 1*/}
                        {currentStep === 1 &&
                            <>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>The {ordinal(currentMoot.mootNumber)} Vis East Moot will take place <strong>IN PERSON</strong> in Hong Kong from 30 March - 6 April 2025. Once again the oral arguments will take place mainly at the West Wing in Justice Place, 11 Ice House Street, Central, Hong Kong Island. All associated events such as the Opening Ceremony and Welcome Reception, Assembly, and Final Round and Awards Banquet will also take place in Hong Kong.</Typography>
                                </Grid>

                                <Grid container item spacing={3} xs={12}
                                      style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                                    <Grid item xs={12} style={{marginBottom: "1rem"}}>
                                        <Typography variant="h6">Personal Information</Typography>
                                    </Grid>                                   
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                value={arbitratorData.prefix || ''}
                                                onChange={e => setArbitratorData({
                                                    ...arbitratorData,
                                                    prefix: e.target.value
                                                })}
                                                name='prefix'
                                                label='Prefix'
                                                helperText='Examples: Dr., Mr., Mrs., Ms., Miss'
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={arbitratorData.firstName || ''}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                firstName: e.target.value
                                            })}
                                            name='firstName'
                                            label='First Name (or equivalent)'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={arbitratorData.lastName || ''}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                lastName: e.target.value
                                            })}
                                            notRequired='true'
                                            name='lastName'
                                            label='Last Name'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='street'
                                            value={arbitratorData.street || ''}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                street: e.target.value
                                            })}
                                            label='Address Line 1'
                                            helperText='Example: Banne Bikslaan 101C'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='unit'
                                            value={arbitratorData.unit  || ''}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                unit: e.target.value
                                            })}
                                            label='Address Line 2'
                                            helperText='Example: 3rd floor'
                                            notRequired
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='city'
                                            value={arbitratorData.city  || ''}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                city: e.target.value
                                            })}
                                            label='City'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='state'
                                            value={arbitratorData.state  || ''}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                state: e.target.value
                                            })}
                                            label='State/Province'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            freeSolo
                                            autoSelect
                                            onChange={(event,newValue) => setArbitratorData({
                                                ...arbitratorData,
                                                jurisdiction: newValue
                                            })}
                                            value={arbitratorData.jurisdiction}
                                            options={jurisdictions}
                                            renderInput={(params) => <MUITextField {...params}
                                                                                   inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                                   variant="standard" label="Jurisdiction" required /> }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                zip: e.target.value
                                            })}
                                            value={arbitratorData.zip}
                                            label='Postal Code'
                                            helperText='If no postal code, enter "00000"'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                contactPhone: e.target.value
                                            })}
                                            value={arbitratorData.contactPhone}
                                            label='Contact Phone Number'
                                            helperText='Include (country code)(regional code)(local number)'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                organization: e.target.value
                                            })}
                                            value={arbitratorData.organization}
                                            label='Firm/Organization/Affiliation'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            onChange={(event, newValue) => setArbitratorData({
                                                ...arbitratorData,
                                                nationality: newValue
                                            })}
                                            value={arbitratorData.nationality}
                                            options={NATIONALITIES}
                                            freeSolo
                                            autoSelect
                                            required
                                            renderInput={(params) => <MUITextField {...params}
                                                                                   inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                                   variant="standard" label="Nationality" required />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Select
                                            name={`gender`}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                gender: e.target.value
                                            })}
                                            value={arbitratorData.gender}
                                            options={GENDERS}
                                            label={'Gender'}
                                            required
                                            helperText='*For statistical purposes only'
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {currentStep === 2 &&
                            <>
                            <Grid container item xs={12}
                                  style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>                                
                                 <Grid item xs={12} style={{marginBottom: "1rem"}}>
                                        <Typography variant="h6">Professional Information & Qualifications</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Profile photo (optional)</Typography>
                                    <ImageResize existingImage={arbitratorData.profilePicUrl}
                                                onImageCropped={img => {
                                        setArbitratorData({
                                            ...arbitratorData,
                                            profilePic: img
                                        })
                                    }
                                    } />
                                </Grid>                        
                                <Grid item xs={12}>
                                    <TextField
                                        multiline={true}
                                        minRows={4}
                                        onChange={e => {setArbitratorData({
                                            ...arbitratorData,
                                            bio: e.target.value
                                        }); 
                                        countWords(e.target.value)}}
                                        value={arbitratorData.bio}
                                        label='Please insert a short bio of no more than 100 words '
                                        helperText={wordCount + '/100 words (will be available publicly on the Vis East Website)'}
                                        error={wordCount > 100 ? true : false}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                        <FormControl component='fieldset'>
                                            <FormLabel component='legend' required>
                                                Primary Occupation
                                            </FormLabel>
                                            <RadioGroup
                                                value={arbitratorData.occupation}
                                                onChange={e => setArbitratorData({
                                                    ...arbitratorData,
                                                    occupation: e.target.value
                                                })}
                                                row>
                                                <FormControlLabel
                                                    value="Academic"
                                                    control={<Radio color='primary' required/>}
                                                    label='Academic'
                                                />
                                                <FormControlLabel
                                                    value="Arbitrator"
                                                    control={<Radio color='primary' required/>}
                                                    label='Arbitrator'
                                                />
                                                <FormControlLabel
                                                    value="Expert"
                                                    control={<Radio color='primary' required/>}
                                                    label='Expert'
                                                />
                                                <FormControlLabel
                                                    value="Lawyer"
                                                    control={<Radio color='primary' required/>}
                                                    label='Lawyer'
                                                />
                                                <FormControlLabel
                                                    value="Other"
                                                    control={<Radio color='primary' required/>}
                                                    label='Other'
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            My primary legal background is
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.background}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                background: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="common law"
                                                control={<Radio color='primary' required/>}
                                                label='Common Law'
                                            />
                                            <FormControlLabel
                                                value="civil law"
                                                control={<Radio color='primary' required/>}
                                                label='Civil Law'
                                            />
                                            <FormControlLabel
                                                value="both"
                                                control={<Radio color='primary' required/>}
                                                label='Both'
                                            />
                                            <FormControlLabel
                                                value="neither"
                                                control={<Radio color='primary' required/>}
                                                label='Neither'
                                            />
                                            <FormControlLabel
                                                value="other"
                                                control={<Radio color='primary' required/>}
                                                label='Other (e.g. you are not a legal practitioner)'
                                            />                                            
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            How many "real" cases have you arbitrated (as sole arbitrator, co-arbitrator, or chair)?
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.arbitrationCaseCount}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                arbitrationCaseCount: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="0-2"
                                                control={<Radio color='primary' required/>}
                                                label='0-2'
                                            />
                                            <FormControlLabel
                                                value="3-5"
                                                control={<Radio color='primary' required/>}
                                                label='3-5'
                                            />
                                            <FormControlLabel
                                                value="5-10"
                                                control={<Radio color='primary' required/>}
                                                label='5-10'
                                            />
                                            <FormControlLabel
                                                value="11-20"
                                                control={<Radio color='primary' required/>}
                                                label='11-20'
                                            />
                                            <FormControlLabel
                                                value="21+"
                                                control={<Radio color='primary' required/>}
                                                label='21+'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            How many "real" cases have you participated in as legal counsel or in-house counsel?
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.counselCaseCount}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                counselCaseCount: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="0-2"
                                                control={<Radio color='primary' required/>}
                                                label='0-2'
                                            />
                                            <FormControlLabel
                                                value="3-5"
                                                control={<Radio color='primary' required/>}
                                                label='3-5'
                                            />
                                            <FormControlLabel
                                                value="5-10"
                                                control={<Radio color='primary' required/>}
                                                label='5-10'
                                            />
                                            <FormControlLabel
                                                value="11-20"
                                                control={<Radio color='primary' required/>}
                                                label='11-20'
                                            />
                                            <FormControlLabel
                                                value="21+"
                                                control={<Radio color='primary' required/>}
                                                label='21+'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>                                
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            How many "real" cases have you participated in as tribunal secretary?
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.tribunalCaseCount}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                tribunalCaseCount: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="0-2"
                                                control={<Radio color='primary' required/>}
                                                label='0-2'
                                            />
                                            <FormControlLabel
                                                value="3-5"
                                                control={<Radio color='primary' required/>}
                                                label='3-5'
                                            />
                                            <FormControlLabel
                                                value="5-10"
                                                control={<Radio color='primary' required/>}
                                                label='5-10'
                                            />
                                            <FormControlLabel
                                                value="11-20"
                                                control={<Radio color='primary' required/>}
                                                label='11-20'
                                            />
                                            <FormControlLabel
                                                value="21+"
                                                control={<Radio color='primary' required/>}
                                                label='21+'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} style={{marginTop: "1rem"}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            disableFuture={true}
                                            views={['year']}
                                            label="Year graduated"
                                            inputFormat="yyyy"
                                            value={arbitratorData.yearGraduated}                                            
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                yearGraduated: e
                                            })
                                            }
                                            renderInput={(params) => <MUITextField {...params} required />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={4} style={{marginTop: "1rem"}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            format='mm/yyyy'
                                            disableFuture={true}
                                            openTo='year'
                                            views={['year','month']}
                                            name='dateQualified'
                                            label='Date Qualified to Practice'
                                            value={arbitratorData.dateQualified}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                dateQualified: e
                                            })}
                                            renderInput={(params) => <MUITextField {...params} required />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            How many times have you coached a Vis East or Vienna Vis Moot team
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.coachCount}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                coachCount: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="0"
                                                control={<Radio color='primary' required/>}
                                                label='0'
                                            />
                                            <FormControlLabel
                                                value="1-3"
                                                control={<Radio color='primary' required/>}
                                                label='1-3'
                                            />
                                            <FormControlLabel
                                                value="4-9"
                                                control={<Radio color='primary' required/>}
                                                label='4-9'
                                            />
                                            <FormControlLabel
                                                value="10+"
                                                control={<Radio color='primary' required/>}
                                                label='10+'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            How many times have you arbitrated at a prior Vis East or Vienna Vis Moot?
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.arbitrationCount}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                arbitrationCount: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="0"
                                                control={<Radio color='primary' required/>}
                                                label='0'
                                            />
                                            <FormControlLabel
                                                value="1-3"
                                                control={<Radio color='primary' required/>}
                                                label='1-3'
                                            />
                                            <FormControlLabel
                                                value="4-9"
                                                control={<Radio color='primary' required/>}
                                                label='4-9'
                                            />
                                            <FormControlLabel
                                                value="10+"
                                                control={<Radio color='primary' required/>}
                                                label='10+'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "1rem"}}>
                                    <FormControl component='fieldset'>
                                        <FormLabel component='legend' required>
                                            How many times have you competed at a prior Vis East or Vienna Vis Moot?
                                        </FormLabel>
                                        <RadioGroup
                                            value={arbitratorData.competitionCount}
                                            onChange={e => setArbitratorData({
                                                ...arbitratorData,
                                                competitionCount: e.target.value
                                            })}
                                            row>
                                            <FormControlLabel
                                                value="0"
                                                control={<Radio color='primary' required/>}
                                                label='0'
                                            />
                                            <FormControlLabel
                                                value="1-3"
                                                control={<Radio color='primary' required/>}
                                                label='1-3'
                                            />
                                            <FormControlLabel
                                                value="4-9"
                                                control={<Radio color='primary' required/>}
                                                label='4-9'
                                            />
                                            <FormControlLabel
                                                value="10+"
                                                control={<Radio color='primary' required/>}
                                                label='10+'
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            </>
                        }
                        {currentStep === 3 &&
                               <ArbitratorAvailability onOralAvailabilityError={hasError => setHasOralAvailabilityError(hasError) }
                                                       onWrittenAvailabilityError={hasError => setHasWrittenAvailabilityError(hasError) }
                                                       onArbitratorDataChange={newValue => setArbitratorData({
                                                                ...arbitratorData,
                                                                ...newValue
                                                            })} />

                        }
                        {currentStep === 4 &&
                            <Grid item xs={12}>
                                <Typography variant='body1'>The email address you have used in registering is your <strong>unique identification</strong> for the establishment of your Arbitrator Account and Arbitrator Profile, once accepted. It is also our primary means of contacting you. Each time you contact us via email, please use the same email address so that we are certain it is you.</Typography>
                                <Typography variant='body1'>In accordance with the Vis East Moot Rules (“Rules”), the Vis East Moot Foundation ("<strong>VEMF</strong>") complies with the Hong Kong Personal Data (Privacy) Ordinance. By participating in the Vis East Moot Competition ("<strong>VEM</strong>"), you agree that the VEMF may collect and record the information you provide, and may take and display photos for Vis East Moot purposes.</Typography>
                                <Typography variant='body1'>We will use the registration information you provide to create your Arbitrator Profile. The Profile allows us to organize the Vis East Moot and to notify you of events or services of interest to current Vis East Moot participants.  We will occasionally send out invitations or notices from selected third party sponsors, service providers or arbitration organizations, without divulging your name or contacts to the third parties.</Typography>
                                <Typography variant='body1'>Some of the personal information we collect - for example, years of experience, nationality, jurisdiction(s) where you are qualified, and gender - are factors which we consider in composing arbitral panels that will reflect the diversity of our teams and of the international arbitration community. Your name and jurisdiction will be available to the public, on our website, and in some Vis East Moot materials including the Vis East Moot programme booklet. No other information will be provided to third parties except in an anonymous form, for research or statistical purposes.</Typography>
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                    Please confirm by ticking the box below that you acknowledge and agree for the Vis East to process your personal information for the purpose of your participation in the Vis East Moot Competition and to send you information about events related to the Vis East Moot or its principles and goals. Please note, in order to participate in the Vis East Moot Competition, we require participants to tick YES for this option.
                                    </FormLabel>
                                    <RadioGroup
                                        style={{alignItems: 'center'}}
                                        value={arbitratorData.optInShareData !== null && arbitratorData.optInShareData.toString()}
                                        onChange={e => setArbitratorData({
                                            ...arbitratorData,
                                            optInShareData: e.target.value === 'true'
                                        })}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />                                        
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                        While the Vis East Moot is primarily an educational experience focused on the student participants, the Vis East Moot generates data that can itself be useful for educational, statistical and research purposes. For example, rankings and comments on memoranda are part of the data collected at the Vis East Moot. Readers' comments are sent to teams without identifying the reader who made them, UNLESS we have obtained your explicit consent to divulge your personal details.  We also sometimes publish arbitrators' opinions and reflections about the Vis East Moot, but will not identify the author(s) without explicit consent.
                                        Please indicate whether you agree to OPT IN for your information to be used for these purposes by ticking the box below whether you agree for us to relay such information. 
                                    </FormLabel>
                                    <RadioGroup                                        
                                        value={arbitratorData.optInResearch !== null && arbitratorData.optInResearch.toString()}
                                        onChange={e => setArbitratorData({
                                            ...arbitratorData,
                                            optInResearch: e.target.value === 'true'
                                        })}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {/*
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                        The Vis East Administration will sometimes receive requests from third parties for the contact information of arbitrators in order to promote their activities related to international arbitration or international trade law. These sometimes include invitations or requests for sponsorship.
                                        Please indicate by ticking the box below whether you agree to OPT IN and allow the Vis East Moot Administration to pass on your contact details for the purposes of promoting events, opportunities, outreach projects, and research activities related to international arbitration and/or trade law.
                                    </FormLabel>
                                    <RadioGroup
                                        value={arbitratorData.optInPromote !== null && arbitratorData.optInPromote.toString()}
                                        onChange={e => setArbitratorData({
                                            ...arbitratorData,
                                            optInPromote: e.target.value === 'true'
                                        })}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                    */}
                                    <Typography variant='body1'>If you do not want your information retained for future Vis East Moots, please advise us so that we can remove your information from our records.  If you do not wish your photo and short biography to be available to the public, or if you wish to close or cancel your Arbitrator Account, please contact the Vis East Administration at: <a
                                        href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.
                                    </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} style={{marginTop: "1rem", display:"flex", gap:"10px"}}>
                            {currentStep > 1 &&
                                <Button disabled={currentStep === 1} onClick={goBack} variant='contained'
                                        color="secondary">
                                    BACK
                                </Button>
                            }
                            {currentStep < 4 &&
                                <Button  disabled={
                                    (((wordCount > 100) && (currentStep === 2)) ||
                                        (currentStep === 3 && (hasWrittenAvailabilityError || hasOralAvailabilityError)))}
                                    type="submit"  variant='contained' color="primary">
                                    NEXT
                                </Button>
                            }
                            {currentStep === 4 &&
                                <Button disabled={arbitratorData.optInShareData === false || arbitratorData.optInShareData === null}
                                type="submit" variant='contained' color="primary">SUBMIT</Button>
                            }
                            {pending && <CircularProgress size={35} />}
                            {error && <Typography color='error'>{error}</Typography>}
                        </Grid>
                    </Grid>
                </form>
            }
        </Container>
    )
}


export default connect(
    (state,ownProps) => ({
        router: state.router,
        user: state.user,
        existingArbitratorData: state.registration.arbitratorData,
        jurisdictions: state.global.jurisdictionNames,
        selectedArbitrator: state.arbitrator.selectedArbitrator,
        selectedArbitratorSchedule: state.arbitrator.selectedArbitratorSchedule,
        currentMoot: state.moot.currentMoot,
        isArbitratorRegistrationOpen: state.moot.currentMoot.isArbitratorRegistrationOpen,
        isOpenOverride : ownProps.isOpenOverride,
    }),{
        saveAvailability: saveAvailability,
        saveArbitratorRegistrationData: saveArbitratorRegistrationData,
        getSelectedArbitrator: getSelectedArbitrator,
        getAllJurisdictions: getAllJurisdictions,
        getUser: getUser,
        getIsArbitratorRegistrationOpen: getIsArbitratorRegistrationOpen,
        getCurrentMoot: getCurrentMoot
    }
)(ArbitratorRegistration)
