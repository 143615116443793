import {get, isEmpty} from "lodash/fp";
import * as actions from "../actions/scoringActions";


export const getCompleteOralArgumentSchedule = () => async (dispatch, getState) => {
    const existing = get('scoring.completeOralArgumentSchedule',getState())
    if (isEmpty(existing)){
        const result = await (actions.getCompleteOralArgumentSchedule())(dispatch,getState)
        return result
    }
    return existing
}
