import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import Select from "../common/Select";
import {noop, get, includes, isEmpty} from "lodash/fp";
import TextInput from "../common/TextInput";
import { connect } from 'react-redux'
import * as actions from '../../actions/teamActions'
import PropTypes from "prop-types";
import {Button, CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "../common/TextInput";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {isNotEmpty} from "../../utils/funcUtils";
import { ACCEPTED } from '../../consts/status.js'
import Unauthorized from "../Unauthorized";
import {getSelectedTeam, getExistingClarifications} from '../../reducers/teamSelectors'
import {getClarificationCategories} from '../../reducers/globalSelectors'
import {clarificationGridColumnDefinition, shouldOverride} from "../helpers";
import {getCurrentMoot, getIsClarificationOpen} from '../../reducers/mootSelectors'
import {TEAM_OWNER} from "../../consts/roles";
import SwipeHorizontalInstruction from "../common/SwipeHorizontalInstruction"

const ClarificationRequest = ({
                                  router,
                                  clarificationCategories=[],
                                  currentMoot,
                                  isClarificationOpen = {},
                                  clarificationSaveError = '',
                                  existingClarifications = [],
                                  team = {},
                                  user,
                                  saveClarificationRequest = noop,
                                  getIsClarificationOpen = noop,
                                  getClarificationCategories = noop,
                                  getExistingClarifications = noop,
                                  getSelectedTeam = noop,
                                  getCurrentMoot = noop}) => {


    const defaultClarification = { category: '', rationale: '', clarificationSought: ''}
    const [clarification,setClarification] = useState(defaultClarification )
    const [isSaving,setIsSaving] = useState(false);
    const [isLoading,setIsLoading] = useState(true);

    const override = router.location.query.override

    const onSave = async (e) => {

        try {
            e.preventDefault()
            setIsSaving(true)
            const newRequest = await saveClarificationRequest(clarification,override)
            if (newRequest) {
                setClarification(defaultClarification)
            }
        }
        finally {
            setIsSaving(false)
        }
    }

    useEffect(() => {
        async function getData() {
            await getCurrentMoot()
            await getIsClarificationOpen()

            await getClarificationCategories()
            await getExistingClarifications()
            await getSelectedTeam()
            setIsLoading(false)
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const isAllowed = get('status',team) === ACCEPTED

    const shouldShowForm = !isLoading
        && isAllowed
        && includes(TEAM_OWNER,user.roles)
        && (((!isClarificationOpen.notOpenYet && !isClarificationOpen.closed)) || shouldOverride(override,currentMoot))


    return (
    <Container style={{paddingBottom: '2rem'}}>
        { isLoading &&
            <CircularProgress />
        }
        {!isLoading && !isAllowed &&
            <Unauthorized />
        }
        {!isLoading && isAllowed && !isClarificationOpen.notOpenYet && isClarificationOpen.closed && !shouldOverride(override,currentMoot) &&
            <div style={{marginBottom: '4rem'}}>
                <Typography variant='h4' component='h1'>Requests for Clarification are closed</Typography>
            </div>
        }
        {!isLoading && isAllowed && isClarificationOpen.notOpenYet && !isClarificationOpen.closed && !shouldOverride(override,currentMoot) &&
            <div>
                <Typography variant='h4' component='h1'>Requests for Clarification will open soon</Typography>
            </div>
        }

        {shouldShowForm &&
        <form onSubmit={onSave} style={{marginBottom: '4rem'}}>
            <Typography variant='h4' component='h1'>Requests for Clarification</Typography>
            <Select name='category' required
                    onChange={e => setClarification({...clarification, category: e.target.value})}
                    value={clarification.category} label='Category' options={clarificationCategories}/>
            {clarification.category === 'Other questions' && (
                <>
                    <Grid item xs={12} md={6}>
                        <TextField
                            onChange={e => setClarification({...clarification, otherCategory: e.target.value})}
                            value={clarification.otherCategory}
                            name='otherCategory'
                            label='Enter Category'
                        />
                    </Grid>
                </>
            )}
            <TextInput required minRows={4} multiline label='Clarification sought'
                       value={clarification.clarificationSought}
                       onChange={e => setClarification({...clarification, clarificationSought: e.target.value})}/>
            <TextInput required minRows={4} multiline label='Rationale' value={clarification.rationale}
                       onChange={e => setClarification({...clarification, rationale: e.target.value})}/>
            <Button variant='contained'
                    type='submit'
                    color="primary"
                    disabled={isSaving}>Submit</Button>
            {clarificationSaveError &&
            <Typography color='error'>{clarificationSaveError}</Typography>
            }
        </form>
        }
        {isNotEmpty(existingClarifications) && isAllowed &&  ((!isClarificationOpen.notOpenYet) || shouldOverride(override,currentMoot)) &&
        <div style={{width: '100%'}}>
            <h3 style={{marginTop:'0'}}>Submitted Clarifications</h3>
            <div style={{margin: '1rem 0 2rem', width: '100%'}}>
            <DataGridPro
                rows={existingClarifications}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 120}                 
                autoHeight={true}
                columns={clarificationGridColumnDefinition()}
                disableSelectionOnClick
                hideFooter
                sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    '& .MuiDataGrid-cell': {
                        display: 'flex',
                        justifyContent: 'flex-start',  
                        alignItems: 'flex-start',      
                        paddingTop: '8px'
                    },
                    '& .MuiDataGrid-cell div': {
                        maxHeight: '120px',
                        overflowY: 'auto',
                        width: '100%',
                    }
                  }}
            />            
            <SwipeHorizontalInstruction />            
            </div>
        </div>
        }
        {!isLoading && ( !includes(TEAM_OWNER,user.roles) || isClarificationOpen.closed ) && isEmpty(existingClarifications) && isAllowed &&  ((!isClarificationOpen.notOpenYet) || shouldOverride(override,currentMoot)) &&
        <Typography variant='h5' component='h1'>No requests for clarification were submitted</Typography>
        }
    </Container>
  )
}


ClarificationRequest.propTypes = {
    clarificationCategories: PropTypes.arrayOf(PropTypes.string),
    isClarificationOpen : PropTypes.object,
    clarificationSaveError : PropTypes.string,
    existingClarifications: PropTypes.arrayOf(PropTypes.shape({
            category: PropTypes.string,
            clarificationSought: PropTypes.string,
            rationale: PropTypes.string,
            createdAt: PropTypes.string
        })),
    getIsClarificationOpen: PropTypes.func,
    getClarificationCategories: PropTypes.func,
    currentMoot: PropTypes.object,
}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        currentMoot: state.moot.currentMoot,
        clarificationCategories : state.global.clarificationCategories,
        isClarificationOpen: state.moot.currentMoot.isClarificationOpen,
        clarificationSaveError: state.team.clarificationSaveError,
        existingClarifications: state.team.existingClarifications,
        team: state.team.selectedTeam,
        user: state.user,
    }),
    {
        saveClarificationRequest: actions.saveClarificationRequest,
        getIsClarificationOpen: getIsClarificationOpen,
        getClarificationCategories: getClarificationCategories,
        getExistingClarifications: getExistingClarifications,
        getSelectedTeam: getSelectedTeam,
        getCurrentMoot: getCurrentMoot,
    }
)(ClarificationRequest)