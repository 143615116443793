import {combineReducers} from "redux";
import {
    GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED,
    GET_DRAFT_SCORE_SUCCEEDED
} from "../actions/actionTypes/scoringActionTypes";


const completeOralArgumentSchedule = (state=[],action) => {
    const {type, payload} = action
    switch (type) {
        case GET_COMPLETE_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED:
            return payload
        default:
            return state;
    }
}

const draftScore = (state={},action) => {
    const {type, payload} = action
    switch (type) {
        case GET_DRAFT_SCORE_SUCCEEDED:
            return payload
        default:
            return state;
    }
}


export default combineReducers({
    completeOralArgumentSchedule,
    draftScore
})
