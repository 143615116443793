import Container from "@material-ui/core/Container";
import {CircularProgress, Typography, Grid, Button} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {isEmpty, noop} from "lodash/fp";
import {Link, useHistory} from 'react-router-dom'
import {getSelectedTeam} from "../../../reducers/teamSelectors";
import {isNotEmpty} from "../../../utils/funcUtils";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";
import DashboardMenu from "components/common/DashboardMenu";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    buttonRedOutline: {
        border: '1px #dd1f26 solid',
        backgroundColor: '#FFFFFF',
        borderRadius: 0,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#dd1f26',
            boxShadow: 'none',
            color: '#FFFFFF',
        },
    }    
}))
const TeamOwnerDashboard = ({team = {}, moot, getSelectedTeam = noop, getCurrentMoot = noop}) => {

    const history = useHistory()
    const [fetching,setFetching] = useState(false)
    const [error,setError] = useState()

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setFetching(true)
                const team = await getSelectedTeam()
                await getCurrentMoot()
                if (!team){
                    history.replace('/registration')
                }
                setFetching(false)
            }catch(e){
                setFetching(false)
                setError('There was an error loading your information. Please try again later')
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const classes = useStyles()

    const isRegistrationComplete = isNotEmpty(team?.Payments) || team?.paymentType === 'bankTransfer'
    return (
            <Container maxWidth='lg' className={classes.root}>
                { fetching && <CircularProgress /> }
                {error && <Typography style={{ color: 'red' }}>{error}</Typography>}

                {!fetching && !error &&
                    <div>
                        { !isRegistrationComplete &&
                            <div>
                                <Typography variant='h5' component='h1' style={{ margin: '1rem 0' }}>Your Team Registration is incomplete.</Typography>
                                <Typography variant='body1' paragraph>Click <Link to='registration/team'>here</Link> to review your registration information</Typography>
                            </div>
                        }
                        {! team.status && isRegistrationComplete &&
                        <>    
                        <div>
                            <Typography variant='h5' component='h1' style={{ margin: '1rem 0'}}>Your registration has been submitted.</Typography>
                            <Typography variant='body1' paragraph>Thank you for submitting an application to take part in the {ordinal(moot.mootNumber)} Vis East Moot Competition. If your application is complete, the Vis East Moot Administration will review your Application and revert to you shortly to advise if your Application was successful. If you have forgotten to include your School’s name on your payment reference, please send a copy of the proof of payment by email to the Vis East Moot Administration at <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>. For speedier processing, we would also suggest that you provide proof of payment to the Vis East Moot Administration by emailing: <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.
                            </Typography>                            
                        </div>
                        </>
                        }
                        {team.status === 'accepted' && isRegistrationComplete &&
                        <div>
                            <DashboardMenu role="TEAM" />  
                            <Typography variant='h6' component='h1' style={{ margin: '2rem 0 1rem' }}>{team.school}, Congratulations on being a part of the {ordinal(moot.mootNumber)} Vis East Moot Competition - Welcome to your Team Account!</Typography>
                            <Typography variant='body1' paragraph>Please ensure that you have read the Key Dates and Times as well as the Rules for the {ordinal(moot.mootNumber)} Vis East Moot Competition. Both are available on the Vis East Website: <a href="https://cisgmoot.org/" target="_blank" className="color-blue" rel="noreferrer">www.cisgmoot.org</a> under the {ordinal(moot.mootNumber)} Vis East Moot Tab.</Typography>
                            <Typography variant='body1' paragraph>The Vis East Moot Administration will rely on the information provided in your Team Account. As such, in order to ensure that certificates of participation and letters or recognition are issued to the appropriate individuals (with correct spelling) and that communications from the Vis East Administration are properly communicated to the Team, please ensure that the information provided in your Team Account is up to date and accurate. Teams are fully responsible for the information provided in the Team Account. The Vis East Moot Administration takes no responsibility for mistakes made as a result of the information on the Team Account.</Typography>
                            <Typography variant='body1' paragraph>Now that your Team has been accepted for the {ordinal(moot.mootNumber)} Vis East Moot Competition, more features are now available on your Team Account. Such features include updating the details of your team members on the Team List, uploading your team’s request for clarifications, uploading your team’s written memoranda, the availability of the claimant’s memorandum you are to respond to, and your schedule for oral arguments. All of these features can be found on the upper right corner of the Team Account by clicking on MENU after logging in. </Typography>
                            <Typography variant='body1' paragraph><strong>PASSWORD SECURITY.</strong> Please note that anyone with access to the password associated with your Team Account will have access to, and can change, any and all details provided. Please therefore ensure that only authorized people have access to the Team Account's username and password.</Typography>
                            <Typography variant='body1' paragraph>For any questions or concerns, whether with your Team Account or the Vis East Moot in general, please do not hesitate to contact the Vis East Moot Administration via email: <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.</Typography>
                        </div>
                        }
                        {team.status === 'hold' && isRegistrationComplete &&
                        <div>
                            <Typography variant='h6' component='h1' style={{ margin: '1rem 0' }}>{team.school}'s Application for the {ordinal(moot.mootNumber)} Vis East Moot Competition</Typography>
                            <Typography variant='body1' paragraph>Thank you again for submitting an application for {team.school} to take part in the {ordinal(moot.mootNumber)} Vis East Moot Competition.</Typography>
                            <Typography variant='body1' paragraph>As you know, the Vis East Moot Competition has limited places. Unfortunately, all of the available slots for the {ordinal(moot.mootNumber)} Vis East Moot are currently filled.</Typography>
                            <Typography variant='body1' paragraph>Notwithstanding the above, teams may drop out of the competition for various reasons. In the event space opens up, we will accept teams from the waitlist to fill the open places. We have therefore placed {team.school} on the waitlist for the {ordinal(moot.mootNumber)} Vis East Moot Competition.</Typography>
                            <Typography variant='body1' paragraph>Should you wish to remain on the waitlist, we will keep your deposit of HKD 1,500 until a final decision has been made. If we are unable to accept you into the {ordinal(moot.mootNumber)} Vis East Moot Competition, we will refund your HKD 1,500 deposit. Teams who have been waitlisted will know, at latest, after the Claimant's memoranda have been submitted whether they have been taken off the waitlist and secured a place in the {ordinal(moot.mootNumber)} Vis East Moot Competition.</Typography>            
                            <Typography variant='body1' paragraph>As we are limited on spacing even for the waitlist, we kindly ask that you inform us within five business days whether {team.school} wishes to be removed from the waitlist. Failing a response within the deadline provided, we will assume {team.school} wishes to remain on the waitlist and we will hold your deposit of HKD 1,500 until a final decision has been made.</Typography>
                            <Typography variant='body1' paragraph>Should you have any questions, please do not hesitate to reach out to the Vis East Moot Administration at <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a></Typography>
                        </div>
                        }
                        {team.status === 'denied' && isRegistrationComplete &&
                        <div>
                            <Typography variant='h6' component='h1' style={{ margin: '1rem 0' }}>{team.school}'s Application for the {ordinal(moot.mootNumber)} Vis East Moot Competition</Typography>
                            <Typography variant='body1' paragraph>Thank you again for submitting an application for {team.school} to take part in the {ordinal(moot.mootNumber)} Vis East Moot Competition.</Typography>
                            <Typography variant='body1' paragraph>As you know, the Vis East Moot has limited places. Unfortunately, all of the available slots for the {ordinal(moot.mootNumber)} Vis East Moot are filled and we are unable to accept your application.</Typography>
                            <Typography variant='body1' paragraph>Thank you again for your interest. We hope that you will submit an application for the {ordinal(moot.mootNumber+1)} Vis East Moot Competition.</Typography>
                            <Typography variant='body1' paragraph>Should you have any questions, please do not hesitate to reach out to the Vis East Moot Administration at <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a></Typography>
                        </div>
                        }
                    </div>
                }
            </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        team: state.team.selectedTeam,
        moot: state.moot.currentMoot
    }),{
        getSelectedTeam: getSelectedTeam,
        getCurrentMoot: getCurrentMoot
    })( TeamOwnerDashboard)