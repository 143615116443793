import {connect} from "react-redux";
import {getDraftScore} from "../../actions/scoringActions";
import {useEffect, useState} from "react";
import _ from "lodash/fp";
import Typography from "@material-ui/core/Typography";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {CircularProgress} from '@material-ui/core';
import {Container, Divider, Grid} from "@mui/material";


const ScoringConfirmation = ({id,draftScore,getDraftScore= _.noop}) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                await getDraftScore(id);
            } finally {
                setLoading(false);
            }
        }
        getData()
    },[])

  

    return (
        <Container maxWidth='sm'>
            {loading &&
                <CircularProgress />
            }

            {!loading &&
            <>     
            <Typography variant='h4' component='h1'>Success!</Typography>
            <Typography variant='body1' style={{fontWeight: 'bold'}}>Show this screen to the Vis East staff prior to leaving the room.</Typography>
            <Typography variant='h6' component='h2' style={{marginBottom: '0'}}>Submission Summary</Typography> 
               
            <Typography variant='body2' style={{marginBottom: '1rem'}}>{`(Submitted ${format(utcToZonedTime(draftScore.createdAt,'Asia/Hong_Kong'),'iii, dd MMMM hh:mm aaa')})`}</Typography>
            <Typography variant='body1' style={{marginBottom:'0.25rem'}}><span style={{ fontWeight: "bold"}}>Round Date: </span>{format(utcToZonedTime(draftScore.scoreData?.time,'Asia/Hong_Kong'),'iii, dd MMMM')}</Typography>
            <Typography variant='body1' style={{marginBottom:'0.25rem'}}><span style={{ fontWeight: "bold"}}>Round Time: </span>{format(utcToZonedTime(draftScore.scoreData?.time,'Asia/Hong_Kong'),'hh:mm aaa')}</Typography>
            
            <Typography variant='body1'><span style={{ fontWeight: "bold"}}>Arbitrator Name: </span>{draftScore.scoreData?.arbitrator}  </Typography>
            <Divider sx={{margin: '1rem 0'}}/>              
            <Typography variant='body1' style={{margin: '1rem 0 0.25rem'}}><span style={{ fontWeight: "bold"}}>Claimant: </span>{draftScore.scoreData?.claimant?.name}  </Typography>
            <Grid container xs={12} style={{maxWidth: '320px', margin: '0 auto'}}>
                <Grid item xs={9}>
                    <Typography variant='body1' style={{fontWeight: 'bold', marginBottom:'0.25rem'}}>Oralist</Typography>  
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' style={{fontWeight: 'bold', textAlign: 'right', marginBottom:'0.25rem'}}>Score</Typography>  
                </Grid>
                <Grid item xs={9}>
                    <Typography variant='body1' style={{lineHeight: '1'}}>{draftScore.scoreData?.firstClaimantOralist?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' style={{textAlign: 'right'}}>{draftScore.scoreData?.firstClaimantOralistScore}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant='body1' style={{lineHeight: '1'}}>{draftScore.scoreData?.secondClaimantOralist?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' style={{textAlign: 'right'}}>{draftScore.scoreData?.secondClaimantOralistScore}</Typography>
                </Grid>
            </Grid>
            <Typography variant='body1' style={{margin: '1rem 0 0.25rem'}}><span style={{ fontWeight: "bold"}}>Respondent: </span>{draftScore.scoreData?.respondent?.name}  </Typography>
            <Grid container xs={12} style={{maxWidth: '320px', margin: '0 auto'}}>
                <Grid item xs={9}>
                    <Typography variant='body1' style={{fontWeight: 'bold', marginBottom:'0.25rem'}}>Oralist</Typography>  
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' style={{fontWeight: 'bold', textAlign: 'right', marginBottom:'0.25rem'}}>Score</Typography>  
                </Grid>
                <Grid item xs={9}>
                    <Typography variant='body1' style={{lineHeight: '1'}}>{draftScore.scoreData?.firstRespondentOralist?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' style={{textAlign: 'right'}}>{draftScore.scoreData?.firstRespondentOralistScore}</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant='body1' style={{lineHeight: '1'}}>{draftScore.scoreData?.secondRespondentOralist?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant='body1' style={{textAlign: 'right'}}>{draftScore.scoreData?.secondRespondentOralistScore}</Typography>
                </Grid>
            </Grid>
            </>
            }
        </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        id: ownProps.match.params.id,
        draftScore : state.scoring.draftScore,
    }), {
        getDraftScore: getDraftScore
    })(ScoringConfirmation)