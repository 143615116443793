import React, {useEffect, useState} from 'react'
import {Button, CircularProgress, FormControlLabel, Link, Snackbar, Tooltip} from '@material-ui/core'
import {DataGridPro, LicenseInfo, GridFooterContainer, GridFooter } from '@mui/x-data-grid-pro';
import {get, includes, isEmpty, noop} from 'lodash/fp';
import {connect} from "react-redux";
import {getSelectedTeam,getTeamById} from "../../reducers/teamSelectors";
import {saveTeamMember, deleteTeamMember, getInviteLink, sendInviteLink} from "../../actions/teamActions";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Select from "../common/Select";
import Grid from "@material-ui/core/Grid";
import TextField from "../common/TextInput";
import TextInput from "../common/TextInput";
import {ACCEPTED} from "../../consts/status";
import {ROLES, GENDERS} from "../../utils/constants";
import {isEmailValid} from "../../utils/validation";
import Unauthorized from "../Unauthorized";
import {Alert, Autocomplete, Checkbox, TextField as MUITextField} from "@mui/material";
import ordinal from "ordinal-number-suffix";
import {asHKCurrency, shouldOverride} from "../helpers";
import {getCurrentMoot, getIsTeamMemberOpen} from "../../reducers/mootSelectors";
import FormGroup from "@material-ui/core/FormGroup";
import TeamMemberActionMenu from "./TeamMemberActionMenu";
import {isNotEmpty} from "../../utils/funcUtils";
import {writeToClipboard} from "../../utils/clipboardUtils";
import {ADMIN, ARBITRATOR, SCORER, TEAM_OWNER} from "../../consts/roles";
import {getFileUrl} from "../../actions/fileActions";
import {getVisaLetterFileName, triggerFileDownloadFromUrl} from "../../utils/fileUtils";
import SwipeHorizontalInstruction from "../common/SwipeHorizontalInstruction"
LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

const TeamsMembers = ({   router,
                          team = {},
                          getSelectedTeam = noop,
                          saveTeamMember = noop,
                          deleteTeamMember = noop,
                          getIsTeamMemberOpen = noop,
                          isTeamMemberOpen = {},
                          teamMemberSaveError,
                          moot,
                          user,
                          inviteLink,
                          inviteLinkErrors,
                          teamId,
                          sendInviteLink = noop,
                          getInviteLink = noop,
                          getCurrentMoot = noop,
                          getFileUrl = noop,
                          getTeamById = noop}) => {

    const [busy, setBusy] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const defaultNewTeamMember = {
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        involvement: '',
        jurisdiction: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        gender: '',
        phone: '',
        willBeInPerson: '',
        isStudent: '',
    }
    const [newTeamMember,setNewTeamMember] = useState(defaultNewTeamMember )
    const [existingInPerson,setExistingInPerson] = useState()
    
    const [isSaving,setIsSaving] = useState(false);
    const [gridActionBusy,setGridActionBusy] = useState(false);
    const [snackbarSeverity,setSnackbarSeverity] = useState('info');
    const [snackbarMessage,setSnackbarMessage] = useState('');

    const override = router.location.query.override

    const [inviteUser,setInviteUser] = useState(false)

    const standardTeamRegistrationFee = Math.abs(moot.inPersonRegistrationFee)
    const smallTeamRegistrationFee = standardTeamRegistrationFee - 500;        
    const excessTeamMemberAdditionalFee = 1500;
    const maxTeamSize = 16;


    const isNonStudentCompetitor = newTeamMember.involvement === 'Competitor' && newTeamMember.isStudent === false
    const isClosed = isTeamMemberOpen.closed
    const isGoingToHK = newTeamMember.willBeInPerson === true 
    const isExistingInPersonFalse = existingInPerson === false // Is the team member's prior status for "willBeInPerson" false?

    const doEmailsMatch = newTeamMember.email ? newTeamMember.email === newTeamMember.confirmEmail : true
    const isIncorrectEmail =
        !isEmpty(newTeamMember.email) &&
        !isEmailValid(newTeamMember.email)

    const isNameSameAsAnother = () => {        

        if(team?.members) {

            let i
            for(i=0; i < team.members.length; i++){      

                if(newTeamMember.firstName.trim().toLowerCase() === team.members[i].firstName.trim().toLowerCase() && 
                    newTeamMember.lastName.trim().toLowerCase() === team.members[i].lastName.trim().toLowerCase() &&
                    newTeamMember.id !== team.members[i].id) {
                    return true
                }
            }
        }
        return false
    }

    const currentTeamSize = (onlyCountGoingToHK = true) => { 
        let count = 0;

        if(team?.members) {

            if(onlyCountGoingToHK === false) {
                return team.members.length;
            }

            for (let i = 0; i < team.members.length; i++) {
                if (team.members[i].willBeInPerson === true) {
                    count++;
                }
            }
        }       
        return count;
    }

    const estimatedTeamRegistrationFee = (teamSize) => {

        if(teamSize < 4) {
            return `${asHKCurrency(smallTeamRegistrationFee,0)}`;
        } else if (teamSize <= 8) {
            return `${asHKCurrency(standardTeamRegistrationFee,0)}`;
        } else {
            return `${ asHKCurrency((teamSize - 8) * excessTeamMemberAdditionalFee + standardTeamRegistrationFee, 0) }`
        }
    }

    const customLocaleText = {
        // Customizing the footer row count label
        footerTotalRows: 'Total Members:',
      };
      

    function CustomFooter () {
        const teamSize = currentTeamSize();
        return (
          <GridFooterContainer style={{justifyContent: 'end'}}>
            {!isClosed &&
            <div style={{margin: '0px 16px'}}>Estimated Team Registration Fee: {estimatedTeamRegistrationFee(teamSize)}</div>
            }
            <div style={{margin: '0px 16px'}}>Total Going to HK: {teamSize}</div>            
            <GridFooter sx={{
              border: 'none', // To delete double border.
              }} />          
          </GridFooterContainer>
        );
      }

    /**
     * isEditing is true when existingInPerson is not empty.
     */
    const isEditing = (existingInPerson === true || existingInPerson === false)

    /**
     * canSaveWhenOpen is true when emails match and are valid and not empty, the person's name is unique
     * within the team and the person is not a non-student competitor and the current team size is under the max team size.
     */
    const canSaveWhenOpen = (doEmailsMatch && !isIncorrectEmail && !isNameSameAsAnother(true) && !isNonStudentCompetitor && (currentTeamSize(false) < maxTeamSize || isEditing))

    /**
     * canSave is true when shouldOverride() returns true and when the following conditions are met:
     * canSaveWhenOpen is true
     * Team Members is closed and the person was already going to Hong Kong or
     * Team Members is closed and the person was NOT going to Hong Kong and they are still not going to Hong Kong or
     * Team Members is not closed.
     */
    const canSave = shouldOverride(override,moot) || ( canSaveWhenOpen && (( (isClosed && existingInPerson) || (isClosed && isExistingInPersonFalse && !isGoingToHK ) ) || !isClosed) )


    const ImportantNotice = () => {        
      
        return (
            <>
                <Typography><strong>IMPORTANT NOTICE:</strong> It is the responsibility of each participant to comply with immigration formalities for both Hong Kong and the Team Member's own jurisdiction. Your passport must be valid for at least six months after your planned entry into Hong Kong. Depending on your nationality, a visitor visa may be necessary. Adequate travel and medical insurance is encouraged, irrespective of whether it is mandated by law. 
                </Typography>
                <Typography>
                According to the Hong Kong Immigration Department, it normally takes at least four to six weeks to process entry visas to Hong Kong. The Immigration Department will not start processing the application until all required documents and information have been received. Depending on how busy the Hong Kong Immigration Department is, it can take up to several months to process visa applications. You should start compiling these documents immediately, and submit your visa application at the <strong>earliest possible date</strong>. Visa Invitation Letters will be available to generate directly on the Team Account by the Authorized Email Account holder from 14 December 2024 onwards. The Vis East Moot bears <strong><u>no</u></strong> responsibility for the actions (or lack of) of the Hong Kong Immigration Department or the Team Members.
                </Typography>
            </>);
      };


    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                if (teamId){
                    await getTeamById(teamId);
                }else{
                    await getSelectedTeam()
                }
                await getIsTeamMemberOpen()
                await getCurrentMoot()
                setIsLoading(false)
            }catch(e){
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const onEdit = async existingMember => {
        existingMember.confirmEmail = existingMember.email
        setExistingInPerson(existingMember.willBeInPerson)
        setNewTeamMember(existingMember)
        const element = document.getElementById("addTeamMember")
        element.scrollIntoView({behavior: "smooth"})
    }

    const onDelete = async existingMember => {
        const result = window.confirm(`Are you sure you want to delete ${existingMember.firstName} ${existingMember.lastName}? `);
        if (result) {
            try {
                setBusy(true)
                await deleteTeamMember(existingMember.id,teamId)
            } finally {
                setBusy(false)
            }
        }
    }

    const onCopyInvite = async existingMember => {
        try{
            setBusy(true)
            setGridActionBusy(true)
            setSnackbarSeverity('info')
            setSnackbarMessage("Please wait while we generate the link")
            const link = await getInviteLink(existingMember)
            if (link) {
                await writeToClipboard(link)
                setSnackbarSeverity('success')
                setSnackbarMessage("Invite link copied to clipboard")
            }else{
                setSnackbarSeverity('error')
                setSnackbarMessage("Member cannot be invited")
            }
        }finally {
            setBusy(false)
        }
    }

    const onEmailInvite = async existingMember => {
        try {
            setBusy(true)
            setGridActionBusy(true)
            setSnackbarSeverity('info')
            setSnackbarMessage("Please wait while we send the invite")
            const result = await sendInviteLink(existingMember)

            if (result) {
                setSnackbarSeverity('error')
                setSnackbarMessage("Member cannot be invited")
            }else{
                setSnackbarSeverity('success')
                setSnackbarMessage("Member invite sent")
            }
        }finally {
            setBusy(false)
        }
    }

    const checkForVisaLetter = async (id) => {
        //call the API to get the time bombed document URL
        try {
            setBusy(true)

            setGridActionBusy(true)
            setSnackbarSeverity('info')
            setSnackbarMessage("Checking for visa letter")
            const fileName = getVisaLetterFileName('TEAM_VISA',moot.id, id,team.id)
            const url = await getFileUrl('TEAM_VISA', fileName)
            if (isNotEmpty(url)) {
                // if we get one back then open the document
                triggerFileDownloadFromUrl(url,fileName)
                setSnackbarSeverity('success')
                setSnackbarMessage("Downloaded visa letter")
            } else {
                // if we don't then show a snackbar with "No visa letter found""
                setSnackbarSeverity('error')
                setSnackbarMessage("No visa letter found")
            }
        }finally {
            setBusy(false)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setGridActionBusy(false)
    };

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1,
            hide: true,
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 200,
            flex: 1,
            renderCell: params => (<a href={`mailto:${params.row.email}`}>{params.row.email}</a>),
            valueGetter: params => (params.row.email)
        },
        {
            field: 'phone',
            headerName: 'Phone',
            flex: 1,
            hide: true,
        },
        {
            field: 'involvement',
            headerName: 'Role',
            width: 120,
        },
        {
            field: 'student',
            headerName: 'Student',
            width: 95,
            valueGetter: params => params.row.isStudent === true ? 'YES' : 'NO'
        },
        {
            field: 'willBeInPerson',
            headerName: 'Going to HK',
            width: 95,
            valueGetter: params => params.row.willBeInPerson === true ? 'YES' : (params.row.willBeInPerson === false ? 'NO': '')
        },
        {
            field: 'Visa',
            headerName: 'Visa Letter',
            width: 150,
            hide: !includes(ADMIN, user.roles),
            renderCell: params => includes(ADMIN, user.roles) ?
                (<Link component="button" style={{textDecoration:"underline"}} onClick={() => checkForVisaLetter(params.id)}>Check for visa letter</Link>):
                <p>Not authorized</p>
        },
        {
            field: 'city',
            headerName: 'City',
            flex: 1,
            hide: true,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 70,
            align: 'right',
            sortable: false,
            renderCell:  (params) => (<TeamMemberActionMenu
                onEdit={() => onEdit(params.row)}
                onDelete={() => onDelete(params.row)}
                onCopyInvite={() => onCopyInvite(params.row)}
                onEmailInvite={() => onEmailInvite(params.row)}
                allDisabled={!isTeamOwner && !isAdmin}
                deleteDisabled={isTeamMemberOpen.closed}
                inviteDisabled={params.row.email === user.username}
            />)
        },
    ]

    const isTeamOwner = includes(TEAM_OWNER,user.roles)
    const isAdmin = includes(ADMIN, user.roles)

    const isAllowed = get('status',team) === ACCEPTED
    const isPending = get('status',team) === null

    const onSave = async e =>  {
        e.preventDefault()        
        let resultWindowConfirm = false;  
        const windowConfirmMessage = `Do you accept the excess team member additional fee of ${asHKCurrency(excessTeamMemberAdditionalFee,0)}?`;
        const isUpdatingExistingRecord = isEditing;  
        const standardMaxTeamSize = 8;
        const teamSize = currentTeamSize();
        let newTeamSize = teamSize; 

       newTeamMember.inviteUser = inviteUser

        if (isUpdatingExistingRecord) {
            // Editing existing member
            // Is member "going to Hong Kong" and was previously not "going to Hong Kong"?
            if (newTeamMember.willBeInPerson && !existingInPerson) {
                // "going to Hong Kong" team size will increase
                newTeamSize += 1;                               
                if(newTeamSize > standardMaxTeamSize) {
                    // New team size is subject to excess team member fees 
                    resultWindowConfirm = window.confirm(windowConfirmMessage);
                }
            } 
        } else {
                // Adding new member
                if (newTeamMember.willBeInPerson) {
                    // "going to Hong Kong" team size will increase
                    newTeamSize += 1;
                    if(newTeamSize > standardMaxTeamSize) {
                        // New team size is subject to excess team member fees 
                        resultWindowConfirm = window.confirm(windowConfirmMessage);
                    }
                }
        }

        // Team size decreased or remained the same size, team size is smaller than 8, or user confirmed excess fee
        if ((newTeamSize <=  teamSize || newTeamSize <= standardMaxTeamSize || resultWindowConfirm === true)) {

            try{               
                
                setIsSaving(true)

                const result = await saveTeamMember(newTeamMember,override,teamId)
                if (result){
                    setNewTeamMember(defaultNewTeamMember)
                    setExistingInPerson('')

                    setTimeout(() => {
                        const element = document.getElementById("teamMembersTable")
                        element.scrollIntoView({behavior: "smooth"})
                    }, 250)
                    
                }
                
            }finally{
                setIsSaving(false)
            }
        }
    }

    return (

        <Container style={{paddingBottom: '10rem'}}>
            { isLoading &&
                <CircularProgress />
            }
            {!isLoading && (!isAllowed || isPending) &&
                <Unauthorized />
            }
            {!isLoading && isAllowed && isTeamMemberOpen.notOpenYet && !isTeamMemberOpen.closed && !shouldOverride(override,moot) &&
                <div>
                    <Typography variant='h4' component='h1'>Team Member additions will open soon</Typography>
                </div>
            }
            { !isLoading && isAllowed && isPending && !isTeamMemberOpen.notOpenYet && !isTeamMemberOpen.closed && !shouldOverride(override,moot) &&
                <div style={{ marginLeft: '4rem' }}>
                    <Typography variant='h5' component='h1'>This section it not yet available</Typography>
                </div>
            }
            {!isLoading && isAllowed && ((isTeamMemberOpen.notOpenYet === false) || shouldOverride(override,moot)) &&
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>                        
                            <Typography variant='h4' component='h1' style={{ margin: '0 0 1rem 0'}}>Team Members</Typography>
                            <Typography>{team.school}’s Team Members Page is where the team is required to list out each official member of {team.school}’s team for the 22nd Vis East Moot Competition (“<strong>VEM</strong>”) in the Team List section. It is <strong>vital</strong> that tall the information on this page is up-to-date and accurate as all Vis East Moot related documents such as Certificates of Participation, Letters of Recognition (when requested), and Visa Invitation Letters (if requested), as well as all communications involving the {ordinal(moot.mootNumber)} VEM will be sent <u>only</u> to eligible individuals that are listed in the Team List and in accordance with the information provided.</Typography>
                            <Typography>Important information for the 22nd VEM can be found in the <a href="https://cisgmoot.org/rules" target="_blank" className="color-blue" rel="noreferrer">{ordinal(moot.mootNumber)} VEM Rules</a> as well as the <a href="https://cisgmoot.org/key-dates-times" target="_blank" className="color-blue" rel="noreferrer">{ordinal(moot.mootNumber)} VEM Key Dates and Times</a> (both of which have been on the VEM website since before Team Registration opened). As a kind reminder, Teams <strong>must</strong> act in full compliance of the Rules and Key Dates & Times.</Typography>
                            <Typography>By adding team members and their contact details in the Team List, you confirm that you have authority from {team.school} to do so. You also confirm that the team members you are adding to the Team List have consented to the use and communication of their personal information for the organizational and operational purposes of the Vis East Moot Competition and agree to receive communications in accordance with Hong Kong's privacy laws and the {ordinal(moot.mootNumber)} VEM Rules.</Typography>                           
                            <Typography><strong>NEW FEATURE:</strong> This year individual team members can have their own Team Member Account where they will have a “read only” access to the Team Account. In order to activate this feature, the Authorized Email Account holder has to give access to each individual team member by ticking the relevant box when adding team members to the Team List or by using the “Send invite” or “Copy invite link” buttons located in the Action column in the Team List. Once a team member is granted access to their own account, the team member will receive a notification in the email that is listed in the Team List to set up their account. While this feature is optional, it will open up more options and allow easy access to information for each Vis East Moot participant during the week of oral arguments.
                            </Typography>                     
                        </Grid>
                    </Grid>
                    <div id="teamMembersTable" style={{margin: '2rem 0 2rem', width: '100%'}}>
                        <Typography variant='h5' component='h2' style={{ margin: '1rem 0'}}>Team List</Typography>
                        <DataGridPro
                        loading={busy}
                        rows={get('members',team) || []}
                        columns={columns}
                        disableSelectionOnClick
                        autoHeight={true}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                          }}
                        localeText={customLocaleText}
                        components={{                            
                            Footer: CustomFooter,
                        }}
                        />                        
                        <SwipeHorizontalInstruction />
                    </div>
                    { (isTeamOwner || isAdmin) &&
                        <>
                            <form onSubmit={onSave}>

                                <Grid id="addTeamMember" container style={{marginTop: '2rem'}} spacing={2}>
                                {
                                /***
                                 * Allow edit of existing Team Member after close date or if not closed.
                                 * Don't show for new Team Member after close date.
                                 */
                                ((isClosed && newTeamMember.willBeInPerson !== '') || (!isClosed) || shouldOverride(override,moot)) ?
                                <>
                                    <Grid item xs={12}>
                                        <Typography variant='h5' component='h2'>{existingInPerson === true || existingInPerson === false ? 'Edit' : 'Add'} Team Member</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            onChange={(event, newValue) => setNewTeamMember({
                                                ...newTeamMember,
                                                involvement: newValue
                                            })}
                                            value={newTeamMember.involvement || ''}
                                            options={ROLES}
                                            autoSelect
                                            renderInput={(params) => <MUITextField {...params} variant="standard" label="Role" required />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Select name='student'
                                                required
                                                onChange={ e => setNewTeamMember({...newTeamMember, isStudent: e.target.value === 'YES'})}
                                                value={newTeamMember.isStudent === true ? 'YES' :  (newTeamMember.isStudent === false ? 'NO' : '') }
                                                label='Student?'
                                                options={['YES', 'NO']}
                                                helperText={ isNonStudentCompetitor ? <Typography variant='caption' color='error'>Non-students can only be coaches, they cannot be competitors.</Typography> : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <Select name='hongKong'
                                                required
                                                onChange={e => setNewTeamMember({...newTeamMember, willBeInPerson: e.target.value === 'YES'})}
                                                value={newTeamMember.willBeInPerson === true ? 'YES' :  (newTeamMember.willBeInPerson === false ? 'NO' : '') }
                                                label='Going to Hong Kong?'
                                                options={['YES', 'NO']}
                                                helperText={ (isClosed && isExistingInPersonFalse && isGoingToHK) && <Typography variant='caption' color='error'>Please contact the Vis East Administration</Typography>}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='First Name'
                                                   value={newTeamMember.firstName}
                                                   onChange={e => setNewTeamMember({...newTeamMember, firstName: e.target.value})}/>

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='Last Name'
                                                   value={newTeamMember.lastName}
                                                   onChange={e => setNewTeamMember({...newTeamMember, lastName: e.target.value})}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='Address Line 1'
                                                   notRequired
                                                   value={newTeamMember.addressLine1}
                                                   onChange={e => setNewTeamMember({...newTeamMember, addressLine1: e.target.value})}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='Address Line 2'
                                                   notRequired
                                                   value={newTeamMember.addressLine2}
                                                   onChange={e => setNewTeamMember({...newTeamMember, addressLine2: e.target.value})}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='City'
                                                   notRequired
                                                   value={newTeamMember.city}
                                                   onChange={e => setNewTeamMember({...newTeamMember, city: e.target.value})}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='State / Province'
                                                   notRequired
                                                   value={newTeamMember.state}
                                                   onChange={e => setNewTeamMember({...newTeamMember, state: e.target.value})}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label='Zip / Postal Code'
                                                   notRequired
                                                   value={newTeamMember.zip}
                                                   onChange={e => setNewTeamMember({...newTeamMember, zip: e.target.value})}/>
                                    </Grid>

                                    <Grid item xs={6} md={4}>
                                        <TextField
                                            name='phoneNumber'
                                            onChange={e => setNewTeamMember({...newTeamMember, phone: e.target.value})}
                                            value={newTeamMember.phone}
                                            label='Phone Number'
                                            helperText='Include (country code) (regional code) (local number)'/>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Select name='gender' required
                                            helperText='For statistical purposes only'
                                            onChange={e => setNewTeamMember({...newTeamMember, gender: e.target.value})}
                                            value={newTeamMember.gender} label='Gender' options={GENDERS}/>

                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='email'
                                            autocomplete='email'
                                            onChange={e => setNewTeamMember({...newTeamMember, email: e.target.value})}
                                            value={newTeamMember.email}
                                            label='Email'
                                        />
                                        {isIncorrectEmail && <Typography color='error'>Please enter Valid Email</Typography>}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='email-confirm'
                                            onChange={e => setNewTeamMember({...newTeamMember, confirmEmail: e.target.value})}
                                            autocomplete='email'
                                            value={newTeamMember.confirmEmail}
                                            label='Confirm Email'
                                        />
                                        {!doEmailsMatch && <Typography color='error'>Emails must match</Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={inviteUser}
                                                  disabled={!canSave}
                                                  onChange={e => setInviteUser(!inviteUser)}
                                                    />}
                                        label="Invite the team member to join this team account, which gives the team member access to only view (i.e. read-only permission) the details set out in this team account. An invitation will be sent to the email address that is provided above."
                                                />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ImportantNotice/>
                                    </Grid>
                                    <Grid item xs={12} style={{display:'flex', alignItems:'start'}}>
                                        <Button variant='contained'
                                                type='submit'
                                                color="primary"
                                                disabled={isSaving || !canSave}>Submit</Button>
                                        {isSaving &&
                                        <CircularProgress style={{marginLeft: '2rem'}} size={35}/>
                                        }
                                    </Grid>
                                    {isNameSameAsAnother(true) &&
                                     <Typography color='error'>Team Members can only be listed once. It appears that you have inputted this individual as a member of the Team already. If you have two Team Members who have the same name, please differentiate the Team Members when listing out the First and/or Last Name.</Typography>
                                    }
                                    {teamMemberSaveError &&
                                    <Typography color='error'>{teamMemberSaveError}</Typography>
                                    }
                                    {currentTeamSize(false) >= maxTeamSize && !isEditing &&
                                    <Typography color='error'>{maxTeamSize} max participants. Contact The Vis East Moot Administration</Typography>
                                    }

                                </>
                                :
                                <Grid item xs={12}>
                                    <ImportantNotice/>
                                 </Grid>
                                }
                                </Grid>
                            </form>
                            <Snackbar open={gridActionBusy} onClose={handleClose} autoHideDuration={6000} >
                                <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>
                        </>
                    }
                </>
            }
        </Container>
    )
}



TeamsMembers.propTypes = {
    teamMembers: PropTypes.arrayOf(PropTypes.object),
    getSelectedTeam: PropTypes.func,
    isTeamMemberOpen: PropTypes.object,
    saveTeamMember: PropTypes.func,
    teamMemberSaveError: PropTypes.string,
    deleteTeamMember: PropTypes.func,
    getIsTeamMemberOpen: PropTypes.func
}

export default  connect(
    (state, ownProps) => ({
        router: state.router,
        moot: state.moot.currentMoot,
        team: state.team.selectedTeam,
        isTeamMemberOpen: state.moot.currentMoot.isTeamMemberOpen,
        teamMemberSaveError: state.team.teamMemberSaveError,
        user: state.user,
        inviteLinkErrors: state.team.inviteLinkErrors,
        inviteLink: state.team.inviteLink,
        teamId: ownProps.match.params.teamId,
    }),{
        getSelectedTeam: getSelectedTeam,
        saveTeamMember: saveTeamMember,
        deleteTeamMember: deleteTeamMember,
        getIsTeamMemberOpen: getIsTeamMemberOpen,
        getCurrentMoot: getCurrentMoot,
        getInviteLink: getInviteLink,
        sendInviteLink: sendInviteLink,
        getFileUrl: getFileUrl,
        getTeamById: getTeamById
    })( TeamsMembers)