import SwipeIcon from '@mui/icons-material/Swipe';
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SwipeHorizontalInstruction = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box component="section" sx={{ display: isSmallScreen ? 'flex' : 'none' }} style={{margin: "0.5rem 0 0 auto", justifyContent: 'center',  alignItems: 'center', gap: '5px'}} >
            <SwipeIcon style={{color: "rgba(0, 0, 0, 0.54)"}}/>            
            <Typography style={{fontSize:'0.65rem', marginBottom: '0'}}>Swipe left or right to view more content in the table when hidden.</Typography>
         </Box>
    
    )
}

export default SwipeHorizontalInstruction;