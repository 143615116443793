import {connect} from "react-redux";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import _, {isEmpty, filter} from "lodash/fp";
import { saveScores } from "../../actions/scoringActions";
import {
    getCurrentMoot
} from "../../reducers/mootSelectors";
import {
    getCompleteOralArgumentSchedule
} from '../../reducers/scoringSelectors';
import {getSelectedArbitrator} from "../../reducers/arbitratorSelectors";
import ordinal from "ordinal-number-suffix";
import {Alert, Autocomplete, Container, Divider, TextField as MUITextField} from "@mui/material";
import {Button, CircularProgress, Snackbar} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import Select from "../common/Select";
import { format } from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import TextInput from "../common/TextInput";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {useHistory} from "react-router-dom";
import {teamNameTextOnly} from 'components/team/TeamName';
import schedule from "../dashboard/admin/OralArgumentsDashboard/Schedule";
import {getMootRoundDateOptions, getMootRoundTimeOptions} from "../../utils/dateUtils";

const Scoring = ({currentMoot,
                     user,
                     arbitrator,
                     oralArgumentSchedule = [],
                     getCurrentMoot = _.noop,
                     getCompleteOralArgumentSchedule = _.noop,
                     getSelectedArbitrator = _.noop,
                     saveScores = _.noop,
                     pairingId}) => {

    const [loading,setLoading] = useState(true);
    const [pending,setPending] = useState(false)
    const [saveScoreError,setSaveScoreError] = useState(false)
    const [scoreData,setScoreData] = useState({});
    const history = useHistory()


    const getPairingsForSelectedDateAndTime = () => {
        // Find the pairings in the schedule for this date and time
        return _.filter(s => s.pairingTime === scoreData?.time,oralArgumentSchedule)
    }
    const getSelectedClaimant = () => {
        return _.find(p => p.claimantId === scoreData.claimant?.id,getPairingsForSelectedDateAndTime())?.claimant
    }

    const getSelectedRespondent = () => {
        return _.find(p => p.respondentId === scoreData.respondent?.id,getPairingsForSelectedDateAndTime())?.respondent
    }
    const handleDataChange = data => {
        if (data?.claimant?.id !== scoreData?.claimant?.id) {
            //We switched claimants so clear the cascading fields
            data.respondent = undefined
            data.firstClaimantOralist = undefined
            data.secondClaimantOralist = undefined
            data.firstRespondentOralist = undefined
            data.secondRespondentOralist = undefined
        }
        setScoreData(data)
    }

    const getSelectedPairingId = () => {
        //Based on what is filled out we can figure out what pairing they are scoring
        const pairings = getPairingsForSelectedDateAndTime()
        if (_.size(pairings) > 0) {
            const pairing = _.find(p => p.claimantId === scoreData.claimant?.id && p.respondentId === scoreData.respondent?.id,pairings)
            return pairing?.id
        }
    }

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                await getCurrentMoot()
                const schedule = await getCompleteOralArgumentSchedule()
               
                if(!isEmpty(user)) {
                   const arbitratorData = await getSelectedArbitrator()
                    //if a pairingId was passed in let's preselect that one
                    if (pairingId && !isEmpty(arbitratorData) && !isEmpty(schedule)) {
                        const pairing = _.find(p => p.id === Number(pairingId),schedule)
                        setScoreData({
                            ...scoreData,
                            date: pairing?.pairingTime,
                            time: pairing?.pairingTime,
                            claimant: {id: pairing.claimant?.id, name: teamNameTextOnly(pairing.claimant)},
                            respondent: {id: pairing.respondent?.id, name: teamNameTextOnly(pairing.respondent)},
                            arbitrator: arbitratorData?.firstName + ' ' + arbitratorData?.lastName,
                        })                        
                    }
                   
                }
            } finally {
                setLoading(false);
            }
        }
        getData()
    },[])

    const saveScore = async (e) => {
        e.preventDefault()
        try {
            const result = window.confirm(`Are you sure this information is correct and ready to be submitted? `);
            if (result) {
                setPending(true)
                const pairingId = getSelectedPairingId()
                const result = await saveScores(pairingId, scoreData)
                setPending(false)
                if (result) {
                    history.replace(`/score-confirmation/${result.id}`)
                }else{
                    setSaveScoreError('Error saving scores. Please try again')
                }
            }
        }
        catch(e) {
            setPending(false)
            setSaveScoreError('Error saving scores. Please try again')
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaveScoreError(false)
    };


    const getClaimantSchoolOptions = () => {
        const pairings = getPairingsForSelectedDateAndTime()
        return _.map(p => ({
            name: teamNameTextOnly(p.claimant),
            id: p.claimantId,
        }),pairings)
    }

    const getRespondentSchoolOptions = () => {
        const pairings = getPairingsForSelectedDateAndTime()

        const respondentPairing = _.find(p => p.claimantId === scoreData.claimant?.id ,pairings)
        return [{
            name: teamNameTextOnly(respondentPairing?.respondent),
            id: respondentPairing?.respondentId
        }]
    }


    const getOralistOptions = (members) => {
        return _.pipe(
            _.filter(m => m.willBeInPerson === true && m.isStudent),
            _.flatMap(m => ({ id: m.id, name: `${m.firstName} ${m.lastName}`})),
            _.uniqBy('id'),
            _.compact)
        (members)
    }

    const getClaimantOralistOptions = () => {
        const claimant = getSelectedClaimant()
        const oralists = getOralistOptions(claimant?.members)
        // Exclude the already selected option
        return _.filter(o => o.id !== scoreData.firstClaimantOralist?.id && o.id !== scoreData.secondClaimantOralist?.id ,oralists)
    }

    const getRespondentOralistOptions = () => {
        const respondent = getSelectedRespondent()
        const oralists = getOralistOptions(respondent?.members)
        // Exclude the already selected option
        return _.filter(o => o.id !== scoreData.firstRespondentOralist?.id && o.id !== scoreData.firstRespondentOralist?.id ,oralists)
    }

    const areOptionsEqual = (option,value) => {
        return option.id === value?.id
    }

    const scoringHelperText = 'Scoring is on a scale of 50-100 points for each oralist: 50 – 59 = needs improvement; 60 – 74 = good; 75 – 90 = very good; 91 – 100 = excellent.'

    return (
        <Container style={{paddingBottom: '1rem', paddingTop: '1rem'}}>
            {loading &&
                <CircularProgress />
            }
            {!loading &&
                <>
                    <form onSubmit={saveScore}>
                        <Grid container item xs={12}>
                            <Grid container item xs={12} style={{padding: '0.5rem'}}>
                                <Grid item xs={12}>
                                    {!isEmpty(arbitrator) &&
                                        <Button style={{ margin: '0 0 2rem 0' }} variant='text' onClick={() => history.go(-1)} >
                                        <ArrowBackIosIcon /> Back to Oral Arguments
                                        </Button>
                                    }                        
                                    <Typography variant='h4' component='h1' style={{ margin: '0 0 1rem 0'}}>{ordinal(currentMoot.mootNumber)} Vis East Moot Oral Argument Score Sheet</Typography>                                    
                                </Grid>

                                <Grid item xs={12}>
                                        <Select
                                        onChange={(e) => handleDataChange({
                                            ...scoreData,
                                            date: e.target.value,
                                        })}
                                        label='Round Date'
                                        value={scoreData.date}
                                        options={getMootRoundDateOptions(oralArgumentSchedule)}
                                        required
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        onChange={(e) => handleDataChange({
                                            ...scoreData,
                                            time: e.target.value,
                                        })}
                                        label='Round Time'
                                        value={scoreData.time}
                                        options={getMootRoundTimeOptions(scoreData?.date,oralArgumentSchedule)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label='Arbitrator Name'
                                        onChange={e => handleDataChange({
                                            ...scoreData,
                                            arbitrator: e.target.value,
                                        })}
                                        value={scoreData.arbitrator}
                                 />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} style={{margin: '1rem 0'}}> 
                            <Divider sx={{opacity:'0'}}/>
                        </Grid>
                        <Grid container item xs={12} style={{backgroundColor: 'rgb(240, 240, 240)', padding: '0 0.5rem 1rem'}}>
                            <Grid container item xs={12} style={{padding: '0.5rem'}}>
                                <Grid item xs={12} style={{marginBottom: '1rem'}}>
                                    <Autocomplete
                                        onChange={(e,newValue) => handleDataChange({
                                            ...scoreData,
                                            claimant: newValue,
                                        })}
                                        value={scoreData.claimant}
                                        options={getClaimantSchoolOptions()}
                                        isOptionEqualToValue={areOptionsEqual}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="Claimant Law School"
                                                                            required/>}

                                    />
                                </Grid> 
                            </Grid>  
                            <Grid container item xs={12} style={{backgroundColor: 'rgb(248, 248, 248)', padding: '0.5rem'}}>                         
                                <Grid item xs={12}>
                                    <Autocomplete
                                        onChange={(e,newValue) => handleDataChange({
                                            ...scoreData,
                                            firstClaimantOralist: newValue,
                                        })}
                                        value={scoreData.firstClaimantOralist}
                                        options={getClaimantOralistOptions()}
                                        isOptionEqualToValue={areOptionsEqual}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="First Oralist"
                                                                            required/>}

                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop:'0.75rem'}}>
                                    <TextInput type="number"
                                            required
                                            onChange={e => setScoreData({
                                                ...scoreData,
                                                firstClaimantOralistScore: e.target.value
                                            })}
                                            value={scoreData.firstClaimantOralistScore}
                                            label='Score'
                                            helperText={scoringHelperText}
                                            min={50}
                                            max={100}
                                    />
                                </Grid>
                            </Grid>    
                            <Grid container item style={{margin:'0.5rem 0'}}></Grid>
                            <Grid container item xs={12} style={{backgroundColor: 'rgb(248, 248, 248)', padding: '0.5rem'}}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        onChange={(e,newValue) => handleDataChange({
                                            ...scoreData,
                                            secondClaimantOralist: newValue,
                                        })}
                                        value={scoreData.secondClaimantOralist}
                                        options={getClaimantOralistOptions()}
                                        isOptionEqualToValue={areOptionsEqual}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="Second Oralist"
                                                                            required/>}

                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop:'0.75rem'}}>
                                    <TextInput type="number"
                                            required
                                            onChange={e => setScoreData({
                                                ...scoreData,
                                                secondClaimantOralistScore: e.target.value
                                            })}
                                            value={scoreData.secondClaimantOralistScore}
                                            label='Score'
                                            helperText={scoringHelperText}
                                            min={50}
                                            max={100}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={2} style={{margin: '1.5rem 0'}}> 
                            <Divider sx={{opacity:'0'}}/>
                        </Grid>
                        <Grid container item xs={12} style={{backgroundColor: 'rgb(240, 240, 240)', padding: '0 0.5rem 1rem'}}>
                            <Grid container item xs={12} style={{padding: '0.5rem'}}>
                                <Grid item xs={12} style={{marginBottom: '1rem'}}>
                                    <Autocomplete
                                        onChange={(e,newValue) => handleDataChange({
                                            ...scoreData,
                                            respondent: newValue,
                                        })}
                                        value={scoreData.respondent}
                                        options={getRespondentSchoolOptions()}
                                        isOptionEqualToValue={areOptionsEqual}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="Respondent Law School"
                                                                            required/>}

                                    />
                                </Grid>
                            </Grid>                            
                            <Grid container item xs={12} style={{backgroundColor: 'rgb(248, 248, 248)', padding: '0.5rem'}}>                         
                                <Grid item xs={12}>
                                    <Autocomplete
                                        onChange={(e,newVaue) => handleDataChange({
                                            ...scoreData,
                                            firstRespondentOralist: newVaue,
                                        })}
                                        value={scoreData.firstRespondentOralist}
                                        options={getRespondentOralistOptions()}
                                        isOptionEqualToValue={areOptionsEqual}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="First Oralist"
                                                                            required/>}

                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop:'0.75rem'}}>
                                    <TextInput type="number"
                                            required
                                            onChange={e => setScoreData({
                                                ...scoreData,
                                                firstRespondentOralistScore: e.target.value
                                            })}
                                            value={scoreData.firstRespondentOralistScore}
                                            label='Score'
                                            helperText={scoringHelperText}
                                            min={50}
                                            max={100}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item style={{margin:'0.5rem 0'}}></Grid>
                            <Grid container item xs={12} style={{backgroundColor: 'rgb(248, 248, 248)', padding: '0.5rem'}}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        onChange={(e,newValue) => handleDataChange({
                                            ...scoreData,
                                            secondRespondentOralist: newValue,
                                        })}
                                        value={scoreData.secondRespondentOralist}
                                        options={getRespondentOralistOptions()}
                                        isOptionEqualToValue={areOptionsEqual}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <MUITextField {...params} variant="standard" label="Second Oralist"
                                                                            required/>}

                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop:'0.75rem'}}>
                                    <TextInput type="number"
                                            required
                                            onChange={e => setScoreData({
                                                ...scoreData,
                                                secondRespondentOralistScore: e.target.value
                                            })}
                                            value={scoreData.secondRespondentOralistScore}
                                            label='Score'
                                            helperText={scoringHelperText}
                                            min={50}
                                            max={100}
                                    />
                                </Grid>           
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{marginTop: '1.5rem', display:'flex', alignItems: 'center'}}>
                            <Button variant='contained'
                                    type='submit'
                                    color='primary'
                                    disabled={pending}>Submit</Button>
                            {pending && <CircularProgress size={35} style={{marginLeft: '2rem'}}/>}
                        </Grid>
                    </form>
                    <Snackbar open={saveScoreError} onClose={handleClose} autoHideDuration={6000}>
                        <Alert severity='error' sx={{width: '100%'}}>
                            {saveScoreError}
                        </Alert>
                    </Snackbar>
                </>
            }
        </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        currentMoot: state.moot.currentMoot,
        user: state.user,
        arbitrator: state.arbitrator.selectedArbitrator,
        oralArgumentSchedule : state.scoring.completeOralArgumentSchedule,
        pairingId : ownProps.match.params.pairingId
    }), {
        getCurrentMoot: getCurrentMoot,
        getSelectedArbitrator: getSelectedArbitrator,
        getCompleteOralArgumentSchedule: getCompleteOralArgumentSchedule,
        saveScores: saveScores
    })(Scoring)