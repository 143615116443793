import React, {useState} from 'react'
import {DataGridPro, GridToolbarContainer, GridToolbarExport, LicenseInfo} from '@mui/x-data-grid-pro';
import {find, flatMap, noop, startCase} from 'lodash/fp';
import {connect} from "react-redux";
import {getAllArbitrators} from "../../../reducers/adminSelectors";
import {addArbitratorToJuryRanking, removeArbitratorFromJuryRanking,publishArbitratorJurySelections} from '../../../actions/adminActions'
import { format } from 'date-fns'

import { TEAM_ROLE_TYPE } from "../../../utils/constants";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Button, Tooltip} from "@material-ui/core";
import {Check, DoNotDisturb, ExpandLess, ExpandMore, Pause} from "@mui/icons-material";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import {withStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {Add, SaveAlt} from "@material-ui/icons";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);



const MemoJuryAssignmentsTable = ({ role  = TEAM_ROLE_TYPE.CLAIMANT,
                                      currentMoot,
                                      getCurrentMoot = noop,
                                      assignableClaimantArbitrators = [],
                                      assignableRespondentArbitrators = [],
                                      juryAssignedClaimantArbitrators = [],
                                      juryAssignedRespondentArbitrators = [],
                                      addArbitratorToJuryRanking = noop,
                                      removeArbitratorFromJuryRanking = noop,
                                      getAllArbitrators = noop,
                                      publishArbitratorJurySelections = noop}) => {

    const [isFetching, setFetching] = React.useState(false)

    const isAddedToFinalRanking  = arbitratorId => {
        const toCheck = role === TEAM_ROLE_TYPE.CLAIMANT ? juryAssignedClaimantArbitrators : juryAssignedRespondentArbitrators
        return find(c=> c.id === arbitratorId,toCheck) !== undefined
    }

    const addArbitratorToFinalRankings = async arbitratorId => {
        try {
            setFetching(true)
            await addArbitratorToJuryRanking(arbitratorId,role)
            await getAllArbitrators(true)
        }finally{
            setFetching(false)
        }
    }
    const removeArbitratorFromFinalRankings = async arbitratorId => {
        try {
            setFetching(true)
            await removeArbitratorFromJuryRanking(arbitratorId,role)
            await getAllArbitrators(true)
        }finally{
            setFetching(false)
        }
    }

    const assignmentGridColumns = (showActions = true) => {
        return [
            {
                field: 'id',
                headerName: "Id",
                flex : 1,
                hide: true
            },
            {
                field: 'arbitrator',
                headerName: 'Arbitrator',
                flex: 1,
                valueGetter: params => `${params.row.firstName} ${params.row.lastName}`
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                hide: true,
                valueGetter: params => params.row.user.email
            },
            {
                field: 'jurisdiction',
                headerName: 'Jurisdiction',
                flex: 1,
                hide:true
            },
            {
                field: 'affiliatedSchools',
                headerName: 'School Conflicts',
                flex: 1,
                hide:true
            },
            {
                field: 'conflictingJurisdictions',
                headerName: 'Jurisdiction Conflicts',
                flex: 1,
                hide:true
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Action",
                flex: 1,
                hide: showActions === false,
                getActions: params => showActions ? [
                    <GridActionsCellItem
                        icon={
                            <Tooltip title='Add to final ranking'>
                                <Check htmlColor={isAddedToFinalRanking(params.row.id) ? '' : 'green'}/>
                            </Tooltip>
                        }
                        disabled={isAddedToFinalRanking(params.row.id)}
                        onClick={() => addArbitratorToFinalRankings(params.row.id)}
                        title='Add to final ranking'
                        label="Add to final ranking"/>,
                    <GridActionsCellItem
                        icon={<Tooltip title='Remove from final ranking'><DoNotDisturb htmlColor={!isAddedToFinalRanking(params.row.id) ? '' : 'red'}/></Tooltip>}
                        title='Remove from final ranking'
                        disabled={!isAddedToFinalRanking(params.row.id)}
                        onClick={() => removeArbitratorFromFinalRankings(params.row.id)}
                        label="Remove from final ranking"/>
                ]: []
            }
        ]
    }

  React.useEffect(() => {
    const asyncFetchData = async () => {
      try {
        setFetching(true)
        await getCurrentMoot()
        await getAllArbitrators()
        setFetching(false)
      }catch(e){
        //TODO ?
      }
    }
    asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    function PublishToolbar() {
        const isDraft = role  === TEAM_ROLE_TYPE.CLAIMANT ?
            currentMoot.arbitratorClaimantJuryRankingSelectionPublished === false :
            currentMoot.arbitratorRespondentJuryRankingSelectionPublished === false


        const ColorButton = withStyles(() => ({
            root: {
                color: '#FFFFFF',
                backgroundColor: green[500],
                '&:hover': {
                    backgroundColor: green[700],
                },
            },
        }))(Button);

        return (
            <GridToolbarContainer  style={{justifyContent:'space-between'}}>
                <div style={{display: 'flex', gap: '40px'}}>
                    <GridToolbarExport
                        className="toolbar-button"
                        printOptions={{ disableToolbarButton: true }}
                        csvOptions={{
                            fileName: `Arbitrators Selected For Jury Ranking - ${format(new Date(),'yyyy-MM-dd')}`,
                            utf8WithBom: true,
                        }}
                    />
                </div>
                <div style={{display: 'flex', gap: '40px'}}>
                    <ColorButton variant='contained'
                                 size='small'
                                 onClick={async () => {
                                     try {
                                         setFetching(true)
                                         await publishArbitratorJurySelections(role)
                                         await getCurrentMoot(true)
                                     } finally {
                                         setFetching(false)
                                     }
                                 }}
                                 color='primary'
                                 disabled={isFetching || !isDraft}
                    >Publish</ColorButton>
                </div>
            </GridToolbarContainer>
        );
    }

    function ExportToolbar() {
        return (
            <Tooltip title='Export contains visible columns of the grid'>
                <GridToolbarExport
                    className="toolbar-button"
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                        fileName: `Arbitrators Available For Jury Ranking - ${format(new Date(),'yyyy-MM-dd')}`,
                        utf8WithBom: true,
                    }}
                />
            </Tooltip>
        );
    }
    return (
        <div style={{ display:"flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "4rem"}}>
            <div style={{ height: 700, width: '47%'}}>
                <h3>Select Arbitrators for {startCase(role.toLowerCase())} Final Ranking</h3>
                <DataGridPro
                    loading={isFetching}
                    components={{
                        Toolbar: ExportToolbar,
                    }}
                    rows={role === TEAM_ROLE_TYPE.CLAIMANT ? assignableClaimantArbitrators : assignableRespondentArbitrators}
                    columns={assignmentGridColumns()}
                />
            </div>
            <div style={{height: 700, width: '47%'}}>
                <h3>Selected Arbitrators for {startCase(role.toLowerCase())} Final Ranking</h3>
                <DataGridPro
                    loading={isFetching}
                    components={{
                        Toolbar: PublishToolbar,
                    }}
                    rows={role === TEAM_ROLE_TYPE.CLAIMANT ? juryAssignedClaimantArbitrators : juryAssignedRespondentArbitrators}
                    columns={assignmentGridColumns(false)}
                />
            </div>
        </div>
    )
}

export default connect(
    (state, ownProps) => ({
        currentMoot: state.moot.currentMoot,
        assignableClaimantArbitrators: state.admin.juryAssignableClaimantArbitrators,
        assignableRespondentArbitrators: state.admin.juryAssignableRespondentArbitrators,
        juryAssignedClaimantArbitrators: state.admin.juryAssignedClaimantArbitrators,
        juryAssignedRespondentArbitrators: state.admin.juryAssignedRespondentArbitrators,
        ...ownProps
    }), {
        getCurrentMoot: getCurrentMoot,
        getAllArbitrators: getAllArbitrators,
        addArbitratorToJuryRanking: addArbitratorToJuryRanking,
        removeArbitratorFromJuryRanking: removeArbitratorFromJuryRanking,
        publishArbitratorJurySelections: publishArbitratorJurySelections
    })( MemoJuryAssignmentsTable)
