export const USER_LOGIN_REQUESTED = 'USER_LOGIN_REQUESTED';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGIN_FROM_SESSION_REQUESTED = 'USER_LOGIN_FROM_SESSION_REQUESTED';
export const USER_LOGIN_FROM_SESSION_SUCCEEDED = 'USER_LOGIN_FROM_SESSION_SUCCEEDED';
export const USER_LOGIN_FROM_SESSION_FAILED =    'USER_LOGIN_FROM_SESSION_FAILED';

export const USER_INFO_REQUESTED = 'USER_INFO_REQUESTED';
export const USER_INFO_SUCCEEDED = 'USER_INFO_SUCCEEDED';
export const USER_INFO_FAILED = 'USER_INFO_FAILED';

export const USER_DELETION_REQUESTED = 'USER_DELETION_REQUESTED'
export const USER_DELETION_SUCCEEDED = 'USER_DELETION_SUCCEEDED'
export const USER_DELETION_FAILED = 'USER_DELETION_FAILED'