import makeAction from "./actionCreator";
import * as Actions from "./actionTypes/userActionTypes";
import {login, getCurrentUser} from "../utils/cognitoHelpers";
import {getUserInfo, sendNotification } from "../api";

export const loginUser = (username,password) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.USER_LOGIN_REQUESTED))

    try {
        const result = await login(username,password)
        dispatch(makeAction(Actions.USER_LOGIN_SUCCEEDED, result))
        await getUser()(dispatch,getState)
        return result;
    } catch (e) {
        dispatch(makeAction(Actions.USER_LOGIN_FAILED, {},e))
    }
}

export const loginUserFromSession = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.USER_LOGIN_FROM_SESSION_REQUESTED))

    try {
        const result = await getCurrentUser()
        dispatch(makeAction(Actions.USER_LOGIN_FROM_SESSION_SUCCEEDED, result))
        await getUser()(dispatch,getState)
    } catch (e) {
        dispatch(makeAction(Actions.USER_LOGIN_FROM_SESSION_FAILED, {},e))
    }
}

export const getUser = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.USER_INFO_REQUESTED))

    try {
        const user = await getUserInfo()
        dispatch(makeAction(Actions.USER_INFO_SUCCEEDED, user))
    } catch (e) {
        dispatch(makeAction(Actions.USER_INFO_FAILED, {},e))
    }
}

export const sendDeletionRequest = () => async (dispatch, getState) => {
    dispatch(makeAction(Actions.USER_DELETION_REQUESTED))
    try{
        await sendNotification({ isDeleteRequest: true})
        dispatch(makeAction(Actions.USER_DELETION_SUCCEEDED))
    }catch(e){
        dispatch(makeAction(Actions.USER_DELETION_FAILED))
    }
}