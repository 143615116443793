import React, {useEffect, useState} from "react";
import { Button, Menu, MenuItem, Divider } from '@material-ui/core'
import {logoutUserSession} from "../../utils/cognitoHelpers";
import {connect} from "react-redux";
import {TEAM_OWNER, ARBITRATOR, ADMIN, SCORER, TEAM_MEMBER} from 'consts/roles';
import { replace } from 'connected-react-router'
import {head, noop, includes, isEmpty} from "lodash/fp";
import ContentAdmin from '@mui/icons-material/SupervisorAccount';
import ContentHome from '@mui/icons-material/Home';
import ContentPayment from '@mui/icons-material/Payment';
import ContentRegister from '@mui/icons-material/AppRegistration';
import ContentLogout from '@mui/icons-material/Logout';
import {isNotEmpty} from "../../utils/funcUtils";
import CustomMenuItem from './CustomMenuItem';
import {isRunningMobileApp} from "../../utils/mobileUtils";
import GavelIcon from "@mui/icons-material/Gavel";
import {Login} from "@mui/icons-material";
import {sendDeletionRequest} from '../../actions/userActions'
import {getSelectedTeam} from "../../reducers/teamSelectors";

const MainMenu = ({user = {},  replace = noop, selectedTeam = {},sendDeletionRequest = noop, getSelectedTeam = noop }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [fetching,setFetching] = useState(false);
  const [error,setError] = useState()
  const [busy, setBusy] = useState(false)

  const open = Boolean(anchorEl);

   useEffect(() => {
      const asyncFetchData = async () => {
          try {
              setFetching(true)
              if (includes(TEAM_OWNER,user.roles) || includes(TEAM_MEMBER,user.roles)) {
                const team = await getSelectedTeam(true)
              }
              setFetching(false)
          }catch(e){
              setFetching(false)
              setError('There was an error loading your information. Please try again later')
          }
      }
      asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    logoutUserSession()
    window.location.href = '/login'
  }

  const userRole = (currentUser) => {
    return includes(TEAM_OWNER,currentUser.roles) ? 'Role: Authorized Team Representative' : (includes(TEAM_MEMBER,currentUser.roles) ? 'Role: Team Member' : '')
  }

  const teamMemberInvolvement = currentUser => {
    if(isNotEmpty(currentUser?.TeamMember)) {
      return ' / ' + head(currentUser.TeamMember)?.involvement;
    } 

    return '';
  }

  const onDeleteRequest = async currentUser => {
    handleClose()
    const result = window.confirm(`Do you want to initiate the deletion of your account? The process may take up to 30 days to complete. You will receive an email confirmation when your account has been deleted. Note, if you delete your account before the end of the Vis East Moot season, you will lose all access to and information in your Vis East Account. For Teams, you will lose the ability to participate in the Vis East Moot competition, generate any certificates of participation, view your critique forms, score sheets, or other information uploaded by the Vis East Moot Administration. For Arbitrators, you will be unable to take part in the Vis East Moot competition unless you submit a new application to act as an arbitrator, which will need to be approved by the Vis East Moot Administration.`);
    if (result) {
        try {
            setBusy(true)
            /* Send email notification to admins requesting deletion of account */
            await sendDeletionRequest(currentUser.id)
        } finally {
            setBusy(false)
        }
    }
  }

  const MapAndLocation = () => {
    return (
      <>
        <CustomMenuItem
        href="https://maps.app.goo.gl/ER9VDNCwooSCozun6"
        text="Locate Justice Place"
        />              
        <CustomMenuItem
        href="https://cisgmoot.org/map-of-hearing-rooms"
        text="Map of Hearing Rooms"
        />
      </>
    )
  }

  return (
    <div>
        <>
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{color:'#fff', marginRight:'-12px'}}
          >
            Menu
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              'disablePadding' : true,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
          {!user.username && (
              <CustomMenuItem
                  to="/login"
                  text="Sign In"
                  startIcon={<Login fontSize="small"/>}
                  extraPaddingTop={true}
                  extraPaddingBottom={true}
              />
          )}
          <div>
            { user.username &&
              <MenuItem disabled={true} style={{minHeight: "44px"}}>{user.username}</MenuItem>
            }
            <Divider style={{margin: '0'}}/>
            {!isRunningMobileApp() && (includes(TEAM_OWNER,user.roles) || includes(ARBITRATOR,user.roles) || includes(ADMIN,user.roles) || isEmpty(user.roles)) &&
            <CustomMenuItem
                to="/registration"
                text="Registration"
                startIcon={<ContentRegister fontSize="small"/>}
                extraPaddingTop={true}
                extraPaddingBottom={true}
              />
            }

          </div>
          {(includes(TEAM_OWNER,user.roles) || includes(TEAM_MEMBER,user.roles)) && error && 
          <MenuItem disabled={true} style={{minHeight: "44px",color: 'red'}}>{error}</MenuItem>
          }
          {(includes(TEAM_OWNER,user.roles) || includes(TEAM_MEMBER,user.roles)) && isNotEmpty(selectedTeam) &&
          <div>
            {/* To-do: How can this be supported when registration is closed?
            <MenuItem style={{paddingLeft: '46px' }} to='/registration/team' component={Link}>Update Registration</MenuItem>
            */}
             <Divider style={{margin: '0'}}/>
              <CustomMenuItem
                to={includes(TEAM_OWNER,user.roles) ? '/team-owner' : '/team-member'}
                text="Team Account"
                textBold={true}
                subText={`${userRole(user)} ${teamMemberInvolvement(user)}`}
                startIcon={<ContentHome fontSize="small"/>}
                extraPaddingTop={true}
              />
            <CustomMenuItem
              to="/team/clarification-request"
              text="Clarification Requests"
            />
              <CustomMenuItem
              to="/team/documents"
              text="Written Submissions"
            />
            <CustomMenuItem
              to="/team/members"
              text="Team Members"
            />
            <MapAndLocation />
            <CustomMenuItem
              to="/team/oral-arguments"
              text="Oral Arguments"
            />
              <CustomMenuItem
              to="/team/visa-invitation-letter"
              text="Visa Invitation Letter"
            />
            {!isRunningMobileApp() && (includes(TEAM_OWNER,user.roles)) &&
              <CustomMenuItem
                to="/team/payments"
                startIcon={<ContentPayment fontSize="small"/>}
                text="Payment & Finances"
              />
            }
          </div>
          }
          {user.roles && user.roles.includes(ARBITRATOR) && (
            <div>
              <Divider style={{margin: '0'}}/>
              <CustomMenuItem
                to="/arbitrator"
                startIcon={<ContentHome fontSize="small"/>}
                text="Arbitrator Account"
                textBold={true}
                extraPaddingTop={true}
              />
              <CustomMenuItem
                to="/registration/arbitrator"
                text="Update Registration"
              />
              <CustomMenuItem
                to="/arbitrator/availability"
                text="Availability & Conflicts"
              />
              <CustomMenuItem
              to="/arbitrator/pairings"
              text="Memoranda Rankings"
              />              
              <CustomMenuItem
              href="https://cisgmoot.org/arbitrator-bundle/"
              text="Arbitrator Bundle"
              />
              <MapAndLocation />
              <CustomMenuItem
                to="/arbitrator/oral-arguments"
                text="Oral Arguments"
              />
               <CustomMenuItem
                to="/arbitrator/visa-invitation-letter"
                text="Visa Invitation Letter"
              />
            </div>
          )}
          {user.roles && user.roles.includes(ADMIN) && (
            <div>
              <Divider style={{margin: '0'}}/>
              <CustomMenuItem
                to="/admin"
                startIcon={<ContentAdmin fontSize="small"/>}
                text="Admin Dashboard"
                textBold={true}
                extraPaddingTop={true}
              />
              <CustomMenuItem
                to="/admin/memoAssignments"
                text="Memoranda Assignments"
              />
              <CustomMenuItem
                to="/admin/memoRankings"
                text="Memoranda Rankings"
              />              
              <CustomMenuItem
                to="/admin/notifications"
                text="Notifications"
              />
              <CustomMenuItem
                to="/admin/oral-arguments"
                text="Oral Arguments"
              />
              <CustomMenuItem
                to="/admin/draft-pairings"
                text="Draft Pairings"
              />
              <CustomMenuItem
                to="/admin/settings"
                text="Settings"
              />
            </div>
          )}

          {user.roles && user.roles.includes(SCORER) && (
            <div>
                <Divider style={{margin: '0'}}/>
                <CustomMenuItem
                to="/admin/oral-arguments"
                text="Oral Arguments"
              />
            </div>
          )}

          <div>
            {((includes(TEAM_OWNER,user.roles) || includes(TEAM_MEMBER,user.roles)) && user.roles.includes(ARBITRATOR)) &&
              <Divider style={{margin: '0'}}/>
            }
            <CustomMenuItem
              href="https://cisgmoot.org/events/"
              text="Schedule of Events"
            />
            <CustomMenuItem
              href="https://cisgmoot.org/vis-east-moot/"
              text="FAQ"
            />
            <CustomMenuItem
              href="mailto:info@cisgmoot.org"
              text="Help"
            />
         
            <CustomMenuItem
              href="https://cisgmoot.org/privacy-policy/"
              text="Legal"
            />
            { user.username && isRunningMobileApp() &&
            <CustomMenuItem
                onClick={() => onDeleteRequest(user)}
                text="Delete Account"
              />
              }


          
          </div>
          {user.username && (
            <div>
                <Divider style={{margin: '0'}}/>
                <CustomMenuItem
                  onClick={logout}
                  startIcon={<ContentLogout fontSize="small"/>}
                  text="Sign out"
                  textBold={true}
                  extraPaddingTop={true}
                  extraPaddingBottom={true}
                />
              </div>
          )}
          </Menu>
        </>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    router: state.router,
    user: state.user,
    selectedTeam: state.team.selectedTeam
  }),{
      sendDeletionRequest: sendDeletionRequest,
      replace,
      getSelectedTeam: getSelectedTeam,
  }

)(MainMenu)