export const SAVE_ARBITRATOR_AVAILABILITY_REQUESTED = 'SAVE_ARBITRATOR_AVAILABILITY_REQUESTED'
export const SAVE_ARBITRATOR_AVAILABILITY_SUCCEEDED = 'SAVE_ARBITRATOR_AVAILABILITY_SUCCEEDED'
export const SAVE_ARBITRATOR_AVAILABILITY_FAILED = 'SAVE_ARBITRATOR_AVAILABILITY_FAILED'

export const GET_ARBITRATOR_REQUESTED = 'GET_ARBITRATOR_REQUESTED'
export const GET_ARBITRATOR_SUCCEEDED = 'GET_ARBITRATOR_SUCCEEDED'
export const GET_ARBITRATOR_FAILED =    'GET_ARBITRATOR_FAILED'

export const ADD_ARBITRATOR_AVAILABILITY = 'ADD_ARBITRATOR_AVAILABILITY'
export const REMOVE_ARBITRATOR_AVAILABILITY = 'REMOVE_ARBITRATOR_AVAILABILITY'

export const GET_ARBITRATIOR_CLAIMANT_PAIRINGS_REQUESTED = 'GET_ARBITRATIOR_CLAIMANT_PAIRINGS_REQUESTED';
export const GET_ARBITRATIOR_CLAIMANT_PAIRINGS_SUCCEEDED = 'GET_ARBITRATIOR_CLAIMANT_PAIRINGS_SUCCEEDED';
export const GET_ARBITRATIOR_CLAIMANT_PAIRINGS_FAILED = 'GET_ARBITRATIOR_CLAIMANT_PAIRINGS_FAILED';


export const GET_ARBITRATIOR_RESPONDENT_PAIRINGS_REQUESTED = 'GET_ARBITRATIOR_RESPONDENT_PAIRINGS_REQUESTED';
export const GET_ARBITRATIOR_RESPONDENT_PAIRINGS_SUCCEEDED = 'GET_ARBITRATIOR_RESPONDENT_PAIRINGS_SUCCEEDED';
export const GET_ARBITRATIOR_RESPONDENT_PAIRINGS_FAILED = 'GET_ARBITRATIOR_RESPONDENT_PAIRINGS_FAILED';

export const GET_CLAIMANT_JURY_TEAMS_REQUESTED = 'GET_CLAIMANT_JURY_TEAMS_REQUESTED';
export const GET_CLAIMANT_JURY_TEAMS_SUCCEEDED = 'GET_CLAIMANT_JURY_TEAMS_SUCCEEDED';
export const GET_CLAIMANT_JURY_TEAMS_FAILED =    'GET_CLAIMANT_JURY_TEAMS_FAILED';

export const GET_RESPONDENT_JURY_TEAMS_REQUESTED = 'GET_JURY_RESPONDENT_TEAMS_REQUESTED';
export const GET_RESPONDENT_JURY_TEAMS_SUCCEEDED = 'GET_JURY_RESPONDENT_TEAMS_SUCCEEDED';
export const GET_RESPONDENT_JURY_TEAMS_FAILED =    'GET_JURY_RESPONDENT_TEAMS_FAILED';

export const SAVE_ARBITRATOR_RANKINGS_REQUESTED = 'SAVE_ARBITRATOR_RANKINGS_REQUESTED'
export const SAVE_ARBITRATOR_RANKINGS_SUCCEEDED = 'SAVE_ARBITRATOR_RANKINGS_SUCCEEDED'
export const SAVE_ARBITRATOR_RANKINGS_FAILED = 'SAVE_ARBITRATOR_RANKINGS_FAILED'

export const UPLOAD_CRITICAL_COMMENTS_DOCUMENT_REQUESTED = 'UPLOAD_CRITICAL_COMMENTS_DOCUMENT_REQUESTED'
export const UPLOAD_CRITICAL_COMMENTS_DOCUMENT_SUCCEEDED = 'UPLOAD_CRITICAL_COMMENTS_DOCUMENT_SUCCEEDED'
export const UPLOAD_CRITICAL_COMMENTS_DOCUMENT_FAILED  = 'UPLOAD_CRITICAL_COMMENTS_DOCUMENT_FAILED'

export const SAVE_JURY_RANKINGS_REQUESTED = 'SAVE_JURY_RANKINGS_REQUESTED'
export const SAVE_JURY_RANKINGS_SUCCEEDED = 'SAVE_JURY_RANKINGS_SUCCEEDED'
export const SAVE_JURY_RANKINGS_FAILED = 'SAVE_JURY_RANKINGS_FAILED'


export const SAVE_ARBITRATOR_PROFILE_REQUESTED = 'SAVE_ARBITRATOR_PROFILE_REQUESTED'
export const SAVE_ARBITRATOR_PROFILE_SUCCEEDED = 'SAVE_ARBITRATOR_PROFILE_SUCCEEDED'
export const SAVE_ARBITRATOR_PROFILE_FAILED = 'SAVE_ARBITRATOR_PROFILE_FAILED'

export const SET_ARBITRATOR_SCHOOLS_TO_WATCH = 'SET_ARBITRATOR_SCHOOLS_TO_WATCH'
export const SET_ARBITRATOR_AFFILIATED_SCHOOLS = 'SET_ARBITRATOR_AFFILIATED_SCHOOLS'
export const SET_ARBITRATOR_ROUNDS_YOU_CAN_ARBITRATE = 'SET_ARBITRATOR_ROUNDS_YOU_CAN_ARBITRATE'

export const GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_REQUESTED = 'GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_REQUESTED'
export const GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED = 'GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED'
export const GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_FAILED = 'GET_ARBITRATOR_ORAL_ARGUMENT_SCHEDULE_FAILED'

export const GET_IS_ARBITRATOR_REGISTRATION_OPEN_REQUESTED = 'GET_IS_ARBITRATOR_REGISTRATION_OPEN_REQUESTED'
export const GET_IS_ARBITRATOR_REGISTRATION_OPEN_SUCCEEDED = 'GET_IS_ARBITRATOR_REGISTRATION_OPEN_SUCCEEDED'
export const GET_IS_ARBITRATOR_REGISTRATION_OPEN_FAILED = 'GET_IS_ARBITRATOR_REGISTRATION_OPEN_FAILED'