import Container from '@material-ui/core/Container'
import React from 'react'
import {Typography} from '@material-ui/core'
import MemoRankingsTable from './MemoRankingsTable'
import MemoJuryRankingsTable from './MemoJuryRankingsTable'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../../common/TabPanel'
import { TEAM_ROLE_TYPE } from "../../../utils/constants";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

const AdminMemoRankings = () => {

  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => setTabValue(newValue)


  return (
      <Container maxWidth='xl' className={classes.root}>
        <Typography variant='h4'>Memoranda Rankings</Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Claimant Rankings' />
          <Tab label='Respondent Rankings' />
          <Tab label='Jury Claimant Rankings' />
          <Tab label='Jury Respondent Rankings' />
        </Tabs>
          <TabPanel value={tabValue} index={0}>
              <MemoRankingsTable role={TEAM_ROLE_TYPE.CLAIMANT} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
              <MemoRankingsTable role={TEAM_ROLE_TYPE.RESPONDENT} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
              <MemoJuryRankingsTable role={TEAM_ROLE_TYPE.CLAIMANT} />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
              <MemoJuryRankingsTable role={TEAM_ROLE_TYPE.RESPONDENT} />
          </TabPanel>
      </Container>
  )
}


export default AdminMemoRankings
