export const GET_ALL_ARBITRATORS_REQUESTED = 'GET_ALL_ARBITRATORS_REQUESTED';
export const GET_ALL_ARBITRATORS_SUCCEEDED = 'GET_ALL_ARBITRATORS_SUCCEEDED';
export const GET_ALL_ARBITRATORS_FAILED = 'GET_ALL_ARBITRATORS_FAILED';

export const GET_ALL_TEAMS_REQUESTED = 'GET_ALL_TEAMS_REQUESTED';
export const GET_ALL_TEAMS_SUCCEEDED = 'GET_ALL_TEAMS_SUCCEEDED';
export const GET_ALL_TEAMS_FAILED = 'GET_ALL_TEAMS_FAILED';

export const GET_ALL_CLARIFICATIONS_REQUESTED = 'GET_ALL_CLARIFICATIONS_REQUESTED';
export const GET_ALL_CLARIFICATIONS_SUCCEEDED = 'GET_ALL_CLARIFICATIONS_SUCCEEDED';
export const GET_ALL_CLARIFICATIONS_FAILED = 'GET_ALL_CLARIFICATIONS_FAILED';

export const EXPORT_ARBITRATOR_MISSING_RANKINGS_REQUESTED = 'EXPORT_ARBITRATOR_MISSING_RANKINGS_REQUESTED';
export const EXPORT_ARBITRATOR_MISSING_RANKINGS_SUCCEEDED = 'EXPORT_ARBITRATOR_MISSING_RANKINGS_SUCCEEDED';
export const EXPORT_ARBITRATOR_MISSING_RANKINGS_FAILED =    'EXPORT_ARBITRATOR_MISSING_RANKINGS_FAILED';

export const SET_TEAM_STATUS_REQUESTED = 'SET_TEAM_STATUS_REQUESTED'
export const SET_TEAM_STATUS_SUCCEEDED = 'SET_TEAM_STATUS_SUCCEEDED'
export const SET_TEAM_STATUS_FAILED = 'SET_TEAM_STATUS_FAILED'

export const SET_ARBITRATOR_STATUS_REQUESTED = 'SET_ARBITRATOR_STATUS_REQUESTED'
export const SET_ARBITRATOR_STATUS_SUCCEEDED = 'SET_ARBITRATOR_STATUS_SUCCEEDED'
export const SET_ARBITRATOR_STATUS_FAILED = 'SET_ARBITRATOR_STATUS_FAILED'


export const EXPORT_ALL_TEAM_MEMBERS_REQUESTED = 'EXPORT_ALL_TEAM_MEMBERS_REQUESTED'
export const EXPORT_ALL_TEAM_MEMBERS_SUCCEEDED = 'EXPORT_ALL_TEAM_MEMBERS_SUCCEEDED'
export const EXPORT_ALL_TEAM_MEMBERS_FAILED = 'EXPORT_ALL_TEAM_MEMBERS_FAILED'

export const EXPORT_ALL_TEAM_PAYMENT_RECORDS_REQUESTED = 'EXPORT_ALL_TEAM_PAYMENT_RECORDS_REQUESTED'
export const EXPORT_ALL_TEAM_PAYMENT_RECORDS_SUCCEEDED = 'EXPORT_ALL_TEAM_PAYMENT_RECORDS_SUCCEEDED'
export const EXPORT_ALL_TEAM_PAYMENT_RECORDS_FAILED = 'EXPORT_ALL_TEAM_PAYMENT_RECORDS_FAILED'

export const RUN_PAIRING_REQUESTED = 'RUN_PAIRING_REQUESTED'
export const RUN_PAIRING_SUCCEEDED = 'RUN_PAIRING_SUCCEEDED'
export const RUN_PAIRING_FAILED = 'RUN_PAIRING_FAILED'

export const EXPORT_ALL_MEMO_RANKINGS_REQUESTED = 'EXPORT_ALL_MEMO_RANKINGS_REQUESTED';
export const EXPORT_ALL_MEMO_RANKINGS_SUCCEEDED = 'EXPORT_ALL_MEMO_RANKINGS_SUCCEEDED';
export const EXPORT_ALL_MEMO_RANKINGS_FAILED =    'EXPORT_ALL_MEMO_RANKINGS_FAILED';

export const GET_ALL_CLAIMANT_MEMO_RANKINGS_REQUESTED = 'GET_ALL_CLAIMANT_MEMO_RANKINGS_REQUESTED';
export const GET_ALL_CLAIMANT_MEMO_RANKINGS_SUCCEEDED = 'GET_ALL_CLAIMANT_MEMO_RANKINGS_SUCCEEDED';
export const GET_ALL_CLAIMANT_MEMO_RANKINGS_FAILED =    'GET_ALL_CLAIMANT_MEMO_RANKINGS_FAILED';

export const GET_ALL_RESPONDENT_MEMO_RANKINGS_REQUESTED = 'GET_ALL_RESPONDENT_MEMO_RANKINGS_REQUESTED';
export const GET_ALL_RESPONDENT_MEMO_RANKINGS_SUCCEEDED = 'GET_ALL_RESPONDENT_MEMO_RANKINGS_SUCCEEDED';
export const GET_ALL_RESPONDENT_MEMO_RANKINGS_FAILED =    'GET_ALL_RESPONDENT_MEMO_RANKINGS_FAILED';


export const GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_REQUESTED = 'GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_REQUESTED';
export const GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED = 'GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED';
export const GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_FAILED =    'GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_FAILED';

export const GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_REQUESTED = 'GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_REQUESTED';
export const GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED = 'GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED';
export const GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_FAILED =    'GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_FAILED';

export const SET_TEAM_JURY_STATUS_REQUESTED = 'SET_TEAM_JURY_STATUS_REQUESTED'
export const SET_TEAM_JURY_STATUS_SUCCEEDED = 'SET_TEAM_JURY_STATUS_SUCCEEDED'
export const SET_TEAM_JURY_STATUS_FAILED = 'SET_TEAM_JURY_STATUS_FAILED'

export const GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_REQUESTED = 'GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_REQUESTED';
export const GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_SUCCEEDED = 'GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_SUCCEEDED';
export const GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_FAILED =    'GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_FAILED';

export const GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_REQUESTED = 'GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_REQUESTED';
export const GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_SUCCEEDED = 'GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_SUCCEEDED';
export const GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_FAILED =    'GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_FAILED';


export const GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_REQUESTED = 'GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_REQUESTED';
export const GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED = 'GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED';
export const GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_FAILED =    'GET_ALL_JURY_CLAIMANT_MEMO_ASSIGNMENTS_FAILED';

export const GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_REQUESTED = 'GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_REQUESTED';
export const GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED = 'GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED';
export const GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_FAILED =    'GET_ALL_JURY_RESPONDENT_MEMO_ASSIGNMENTS_FAILED';

export const GET_ORAL_ARGUMENT_SCHEDULE_REQUESTED = 'GET_ORAL_ARGUMENT_SCHEDULE_REQUESTED'
export const GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED = 'GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED'
export const GET_ORAL_ARGUMENT_SCHEDULE_FAILED = 'GET_ORAL_ARGUMENT_SCHEDULE_FAILED'

export const SELECT_PAIRING = 'SELECT_PAIRING';
export const SELECT_ROUND = 'SELECT_ROUND';

export const SAVE_PAIRING_REQUESTED = 'SAVE_PAIRING_REQUESTED'
export const SAVE_PAIRING_SUCCEEDED = 'SAVE_PAIRING_SUCCEEDED'
export const SAVE_PAIRING_FAILED = 'SAVE_PAIRING_FAILED'

export const SAVE_SCORES_REQUESTED = 'SAVE_SCORES_REQUESTED'
export const SAVE_SCORES_SUCCEEDED = 'SAVE_SCORES_SUCCEEDED'
export const SAVE_SCORES_FAILED = 'SAVE_SCORES_FAILED'

export const SAVE_DRAFT_SCORES_REQUESTED = 'SAVE_DRAFT_SCORES_REQUESTED'
export const SAVE_DRAFT_SCORES_SUCCEEDED = 'SAVE_DRAFT_SCORES_SUCCEEDED'
export const SAVE_DRAFT_SCORES_FAILED = 'SAVE_DRAFT_SCORES_FAILED'

export const EXPORT_ALL_ARBITRATOR_CONFLICTS_REQUESTED = 'EXPORT_ALL_ARBITRATOR_CONFLICTS_REQUESTED';
export const EXPORT_ALL_ARBITRATOR_CONFLICTS_SUCCEEDED = 'EXPORT_ALL_ARBITRATOR_CONFLICTS_SUCCEEDED';
export const EXPORT_ALL_ARBITRATOR_CONFLICTS_FAILED = 'EXPORT_ALL_ARBITRATOR_CONFLICTS_FAILED';

export const SAVE_ROUND_WINNERS_REQUESTED = 'SAVE_ROUND_WINNERS_REQUESTED'
export const SAVE_ROUND_WINNERS_SUCCEEDED = 'SAVE_ROUND_WINNERS_SUCCEEDED'
export const SAVE_ROUND_WINNERS_FAILED = 'SAVE_ROUND_WINNERS_FAILED'

export const PAIR_ROUND_REQUESTED = 'PAIR_ROUND_REQUESTED'
export const PAIR_ROUND_SUCCEEDED = 'PAIR_ROUND_SUCCEEDED'
export const PAIR_ROUND_FAILED = 'PAIR_ROUND_FAILED'

export const PAIR_WINNER_ROUND_REQUESTED = 'PAIR_WINNER_ROUND_REQUESTED'
export const PAIR_WINNER_ROUND_SUCCEEDED = 'PAIR_WINNER_ROUND_SUCCEEDED'
export const PAIR_WINNER_ROUND_FAILED = 'PAIR_WINNER_ROUND_FAILED'

export const GET_ROUNDS_REQUESTED = 'GET_ROUNDS_REQUESTED'
export const GET_ROUNDS_SUCCEEDED = 'GET_ROUNDS_SUCCEEDED'
export const GET_ROUNDS_FAILED = 'GET_ROUNDS_FAILED'

export const PUBLISH_ROUND_REQUESTED = 'PUBLISH_ROUND_REQUESTED'
export const PUBLISH_ROUND_SUCCEEDED = 'PUBLISH_ROUND_SUCCEEDED'
export const PUBLISH_ROUND_FAILED = 'PUBLISH_ROUND_FAILED'

export const DOWNLOAD_TEAM_DOCUMENT_REQUESTED = 'DOWNLOAD_TEAM_DOCUMENT_REQUESTED';
export const DOWNLOAD_TEAM_DOCUMENT_SUCCEEDED = 'DOWNLOAD_TEAM_DOCUMENT_SUCCEEDED';
export const DOWNLOAD_TEAM_DOCUMENT_FAILED = 'DOWNLOAD_TEAM_DOCUMENT_FAILED';

export const UPDATE_ARBITRATOR_PAIRING_REQUESTED = 'UPDATE_ARBITRATOR_PAIRING_REQUESTED'
export const UPDATE_ARBITRATOR_PAIRING_SUCCEEDED = 'UPDATE_ARBITRATOR_PAIRING_SUCCEEDED'
export const UPDATE_ARBITRATOR_PAIRING_FAILED = 'UPDATE_ARBITRATOR_PAIRING_FAILED'

export const PUBLISH_ARBITRATOR_PAIRINGS_REQUESTED = 'PUBLISH_ARBITRATOR_PAIRINGS_REQUESTED'
export const PUBLISH_ARBITRATOR_PAIRINGS_SUCCEEDED = 'PUBLISH_ARBITRATOR_PAIRINGS_SUCCEEDED'
export const PUBLISH_ARBITRATOR_PAIRINGS_FAILED = 'PUBLISH_ARBITRATOR_PAIRINGS_FAILED'

export const SAVE_ARBITRATOR_PAIRING_REQUESTED = 'SAVE_ARBITRATOR_PAIRING_REQUESTED'
export const SAVE_ARBITRATOR_PAIRING_SUCCEEDED = 'SAVE_ARBITRATOR_PAIRING_SUCCEEDED'
export const SAVE_ARBITRATOR_PAIRING_FAILED = 'SAVE_ARBITRATOR_PAIRING_FAILED'

export const DELETE_ARBITRATOR_PAIRING_REQUESTED = 'DELETE_ARBITRATOR_PAIRING_REQUESTED'
export const DELETE_ARBITRATOR_PAIRING_SUCCEEDED = 'DELETE_ARBITRATOR_PAIRING_SUCCEEDED'
export const DELETE_ARBITRATOR_PAIRING_FAILED = 'DELETE_ARBITRATOR_PAIRING_FAILED'

export const GET_MEMO_ASSIGNABLE_TEAMS_REQUESTED = 'GET_MEMO_ASSIGNABLE_TEAMS_REQUESTED'
export const GET_MEMO_ASSIGNABLE_TEAMS_SUCCEEDED = 'GET_MEMO_ASSIGNABLE_TEAMS_SUCCEEDED'
export const GET_MEMO_ASSIGNABLE_TEAMS_FAILED = 'GET_MEMO_ASSIGNABLE_TEAMS_FAILED'

export const GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_REQUESTED = 'GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_REQUESTED'
export const GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_SUCCEEDED = 'GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_SUCCEEDED'
export const GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_FAILED = 'GENERATE_WRITTEN_ARBITRATOR_PAIRINGS_FAILED'

export const EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_REQUESTED = 'EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_REQUESTED'
export const EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_SUCCEEDED = 'EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_SUCCEEDED'
export const EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_FAILED = 'EXPORT_WRITTEN_ARBITRATOR_PAIRINGS_FAILED'

export const ADD_ARBITRATOR_TO_JURY_RANKING_REQUESTED = 'ADD_ARBITRATOR_TO_JURY_RANKING_REQUESTED'
export const ADD_ARBITRATOR_TO_JURY_RANKING_SUCCEEDED = 'ADD_ARBITRATOR_TO_JURY_RANKING_SUCCEEDED'
export const ADD_ARBITRATOR_TO_JURY_RANKING_FAILED = 'ADD_ARBITRATOR_TO_JURY_RANKING_FAILED'

export const REMOVE_ARBITRATOR_FROM_JURY_RANKING_REQUESTED = 'REMOVE_ARBITRATOR_FROM_JURY_RANKING_REQUESTED'
export const REMOVE_ARBITRATOR_FROM_JURY_RANKING_SUCCEEDED = 'REMOVE_ARBITRATOR_FROM_JURY_RANKING_SUCCEEDED'
export const REMOVE_ARBITRATOR_FROM_JURY_RANKING_FAILED = 'REMOVE_ARBITRATOR_FROM_JURY_RANKING_FAILED'

export const PUBLISH_ARBITRATOR_JURY_SELECTIONS_REQUESTED = 'PUBLISH_ARBITRATOR_JURY_SELECTIONS_REQUESTED'
export const PUBLISH_ARBITRATOR_JURY_SELECTIONS_SUCCEEDED = 'PUBLISH_ARBITRATOR_JURY_SELECTIONS_SUCCEEDED'
export const PUBLISH_ARBITRATOR_JURY_SELECTIONS_FAILED = 'PUBLISH_ARBITRATOR_JURY_SELECTIONS_FAILED'

export const GET_DRAFT_PAIRINGS_REQUESTED = 'GET_DRAFT_PAIRINGS_REQUESTED'
export const GET_DRAFT_PAIRINGS_SUCCEEDED = 'GET_DRAFT_PAIRINGS_SUCCEEDED'
export const GET_DRAFT_PAIRINGS_FAILED = 'GET_DRAFT_PAIRINGS_FAILED'

export const GENERATE_DRAFT_PAIRINGS_REQUESTED = 'GENERATE_DRAFT_PAIRINGS_REQUESTED'
export const GENERATE_DRAFT_PAIRINGS_SUCCEEDED = 'GENERATE_DRAFT_PAIRINGS_SUCCEEDED'
export const GENERATE_DRAFT_PAIRINGS_FAILED = 'GENERATE_DRAFT_PAIRINGS_FAILED'

export const SAVE_DRAFT_PAIRINGS_REQUESTED = 'SAVE_DRAFT_PAIRINGS_REQUESTED'
export const SAVE_DRAFT_PAIRINGS_SUCCEEDED = 'SAVE_DRAFT_PAIRINGS_SUCCEEDED'
export const SAVE_DRAFT_PAIRINGS_FAILED = 'SAVE_DRAFT_PAIRINGS_FAILED'

export const SAVE_REAL_PAIRINGS_REQUESTED = 'SAVE_REAL_PAIRINGS_REQUESTED'
export const SAVE_REAL_PAIRINGS_SUCCEEDED = 'SAVE_REAL_PAIRINGS_SUCCEEDED'
export const SAVE_REAL_PAIRINGS_FAILED = 'SAVE_REAL_PAIRINGS_FAILED'

export const SEND_NOTIFICATION_REQUESTED = 'SEND_NOTIFICATION_REQUESTED'
export const SEND_NOTIFICATION_SUCCEEDED = 'SEND_NOTIFICATION_SUCCEEDED'
export const SEND_NOTIFICATION_FAILED = 'SEND_NOTIFICATION_FAILED'