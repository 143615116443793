import { Box, Divider, Typography } from '@material-ui/core'

import './Footer.css'

const Footer = () => {

  return (
    <Box className='footer'> 
    <Divider />    
      <Box className="footer-row">        
        <a className="footer-link" href='mailto:info@cisgmoot.org'>Help</a>
      </Box>            
    </Box>
  )
}

export default Footer