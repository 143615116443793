import React from 'react'
import { connect } from 'react-redux'
import './styles/App.css'

import Header from './components/common/Header'
import Footer from './components/common/Footer'

const App = ({router, children = [] }) => {
  return (
      <div className='app'>
        <header>
          <Header />
        </header>
        <main className='app__body'>{children}</main>
        <footer>
          <Footer />
        </footer>
      </div>
  )
}

export default connect((state, ownProps) => ({
    router: state.router,
    children: ownProps.children,
    loading: state.loading
}))(App)
