import Typography from "@material-ui/core/Typography";
import {format, utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
import {parseISO} from "date-fns";
import React from "react";
import _, {filter} from 'lodash/fp'

const localTimeZone = new Date()
    .toLocaleTimeString('en-us', {timeZoneName: 'short'})
    .split(' ')[2]

export const formatInTimeZone = (date, fmt, tz) => {
    return format(utcToZonedTime(date, tz),fmt, { timeZone: tz });
}

export const getCurrentHongKongTime = (fmt = 'MM/dd/yyyy hh:mm aaa') => {
    return formatInTimeZone(parseISO(new Date().toISOString()),fmt,"Asia/Hong_Kong")
}

export const hongKongTimeFromUTC = (date,fmt = 'yyyy/MM/dd hh:mm aaa') => {
    try{
        return  _.isNil(date) ? null : formatInTimeZone(date,fmt,"Asia/Hong_Kong")
    }catch(e){
        return date
    }
}


export const utcFromHongKongTime = date => {
    try {
        return _.isNil(date) ? null : zonedTimeToUtc(date, 'Asia/Hong_Kong')
    }catch(e){
        return date
    }
}

export const dateFormater = (day) => {
    return (
        <span style={{position: 'relative'}}>
                <Typography style={{fontSize: '0.875rem', fontWeight: 'bold'}}>{`${format(utcToZonedTime(day,'Asia/Hong_Kong'), 'eee. d MMMM')}`}</Typography>
                <Typography  style={{
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    bottom: '-12px',
                    left: '0',
                    color: '#dd1f26',
                    fontSize: '0.75rem',
                }}
                >{`${format(parseISO(day), 'eee. d MMMM') + ' ' + localTimeZone}`}
                </Typography>
            </span>
    );
}

export const timeFormater = (day) => {
    return (
        <span style={{position: 'relative'}}>
                <Typography style={{fontSize: '0.875rem', fontWeight: 'bold'}}>{`${format(utcToZonedTime(day,'Asia/Hong_Kong'), 'h:mm aa')}`}</Typography>
                <Typography  style={{
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    bottom: '-12px',
                    left: '0',
                    color: '#dd1f26',
                    fontSize: '0.75rem',
                }}
                >{`${format(parseISO(day), 'h:mm aa') + ' ' + localTimeZone}`}
                </Typography>
            </span>
    );
}

export const dateTimeFormatter = (day) => {
    return (
        <span style={{position: 'relative'}}>
                <Typography style={{fontSize: '0.875rem', fontWeight: 'bold'}}>{`${format(utcToZonedTime(day,'Asia/Hong_Kong'), 'eee. d MMMM')}`}</Typography>
                <Typography  style={{
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    bottom: '-12px',
                    left: '0',
                    color: '#dd1f26',
                    fontSize: '0.75rem',
                }}
                >{`${format(parseISO(day), 'eee. d MMMM h:mm aa') + ' ' + localTimeZone}`}
                </Typography>
            </span>
    );
}



const getJustTheDateInHk = utcDate =>{
    return format(utcToZonedTime(utcDate,'Asia/Hong_Kong'),'iii, dd MMMM')
}
const getJustTheTimeInHk = utcDate =>{
    return format(utcToZonedTime(utcDate,'Asia/Hong_Kong'),'hh:mm aaa')
}

export const getMootRoundDateOptions = (schedule, includeAllRounds = false, field = 'pairingTime') => {

    const filtered = includeAllRounds
        ? schedule
        : _.filter(r => r.roundType === "General Rounds",schedule)

    const result = _.pipe(
        _.map(r => ({
            name: getJustTheDateInHk(r[field]),
            id: r[field],
        })),
        _.uniqBy('name')
    )(filtered)

    return result
}
export const getMootRoundTimeOptions = (date,schedule, field = 'pairingTime') => {
    if (!date) return [];

    return _.pipe(
        filter(r => getJustTheDateInHk(r[field]) === getJustTheDateInHk(date)),
        _.map(r => ({
            name: getJustTheTimeInHk(r[field]),
            id: r[field]
        })),
        _.uniqBy('name')
    )(schedule)
}