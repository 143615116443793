import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';

const { Button } = require('@material-ui/core');

const FaqIcon = () => (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 50.5 50.5">
      <g>
        <path fill="#fff" d="M47.97,10.1h-5.05v22.72H10.1v5.05c0,1.39,1.14,2.52,2.52,2.52h27.77l10.1,10.1V12.62c0-1.39-1.14-2.52-2.52-2.52Z"/>
        <g>
          <path fill="#fff" d="M17.37,9.41h-.03c-.16.64-.32,1.46-.5,2.08l-.64,2.29h2.39l-.67-2.29c-.19-.64-.38-1.44-.54-2.08Z"/>
          <path fill="#fff" d="M28.14,9.35c-1.66,0-2.62,1.63-2.62,3.65-.02,2.05.98,3.62,2.61,3.62s2.61-1.55,2.61-3.67c0-1.97-.93-3.6-2.59-3.6Z"/>
          <path fill="#fff" d="M35.35,0H2.52C1.14,0,0,1.14,0,2.52v35.35l10.1-10.1h25.25c1.39,0,2.52-1.14,2.52-2.52V2.52c0-1.39-1.14-2.52-2.52-2.52ZM12.3,14.04h-3.87v4.34h-2.45V7.59h6.59v2h-4.15v2.46h3.87v1.98ZM19.78,18.37l-.83-2.77h-3.09l-.77,2.77h-2.53l3.3-10.79h3.2l3.35,10.79h-2.62ZM32.66,20.23c-1.54-.45-2.82-.91-4.26-1.52-.24-.1-.5-.14-.75-.16-2.43-.16-4.71-1.95-4.71-5.47,0-3.23,2.05-5.67,5.27-5.67s5.11,2.5,5.11,5.44c0,2.45-1.14,4.18-2.56,4.82v.06c.83.24,1.76.43,2.61.61l-.7,1.89Z"/>
        </g>
    </g>
  </svg>
  );

function CustomButton({ to, href, target, isExternal, startIcon, children, disabled }) {

     const useStyles = makeStyles(() => ({
            customButton: {
                minWidth: '170px',
                backgroundColor: '#dd1f26',                
                color: '#ffffff',
                lineHeight: '1',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: '#F73940',
                },
                "@media (max-width:425px)": {
                  minWidth: 'auto',
                  padding: '10px 14px',                  
                },
            },
            icon: {
                fontSize: '32px',
            },
        }))

    const classes = useStyles()

    return (
        <Button variant="contained" 
          startIcon={startIcon === 'FaqIcon' ? <FaqIcon /> : startIcon} 
          endIcon={isExternal ? <OpenInNewIcon style={{ fontSize: '16px', position: 'absolute', top: '2px', right: '2px' }} /> : ''} 
          className={classes.customButton} 
          to={to ? to : undefined} 
          component={ to ? Link : undefined} 
          href={href ? href : undefined} 
          target={target ? target : undefined}
          disabled={disabled ? disabled : undefined}>
         {children}
        </Button>
    )
}

export default CustomButton;