//import { createTheme } from '@material-ui/core/styles';
import { createTheme } from '@mui/material/styles';
import { lineHeight } from '@mui/system';

const theme = createTheme({
    palette: {
        primary: {
            light: '#484848',
            main: '#212121',
            dark: '#000000',
            contrastText: '#fff',
        },
        secondary: {
            light: '#efefef',
            main: '#bdbdbd',
            dark: '#8d8d8d',
            contrastText: '#000000',
        }
    },
    typography: {
      fontFamily: ['"Avenir Book"', "Arial", "Helvetica", "sans-serif"].join(','),
      h1: {
        fontFamily: '"Avenir Book", Arial, Helvetica, sans-serif',
       },
       body1: {
        margin: '0 0 1em 0',
       },
       h4: {
        '@media (max-width:600px)': {
            fontSize: '1.75rem',
        },
       }
    },
    overrides: {
        MuiInputBase: {
            root: {
                marginBottom: '0',
            },
            input: {
                fontFamily: '"Avenir Book", Arial, Helvetica, sans-serif',
            }
        },
        MuiInput: {            
            underline: {
                '&:before': {
                    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                }
            }
        },
        MuiFormLabel: {
            root: {
                margin: '0',
                color: '#000000',
                lineHeight: '1.25',
            }
        },
        MuiInputLabel: {
            root: {
                margin: '0',
                color: '#000000',
                lineHeight: '1.25',
            }
        },
        MuiFormControlLabel: {
            label: {
                margin: '0',
            }
        },
        MuiMenuItem: {
            root: {
                margin: '0',
                minHeight: '32px',
                lineHeight: '1',
             },           
        },         
        MuiListItem: {
            root: {
                margin: '0',
             },           
        },
        MuiListItemText: {
            root: {
                margin: '0',
             },
        },        
        MuiButton: {            
            label: {
                fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif',
            },
            text: {                
                '&.toolbar-button': {
                    fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif',
                    color: '#000000',
                    '&:hover': {
                        backgroundColor: 'rgba(33, 33, 33, 0.04)',
                    },
                }
            }
        },       
        MuiDataGrid: {
            root: {
                fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif',
             }
        },
    },
})

export default theme;