import Container from '@material-ui/core/Container'
import React, {useState} from 'react'
import {Button, CircularProgress, Snackbar, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import _ from "lodash/fp";
import {getSelectedPairing} from "../../../../reducers/adminSelectors";
import {saveDraftScores} from "../../../../actions/adminActions";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import Grid from "@material-ui/core/Grid";
import {Alert} from "@mui/material";
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TeamName from 'components/team/TeamName';
import './ReviewScores.css'
import Checkbox from "@material-ui/core/Checkbox";
import {hongKongTimeFromUTC} from "../../../../utils/dateUtils";
import {Cached, Refresh} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const ReviewScores = ({selectedPairingId = 0, getSelectedPairing = _.noop, selectedPairing = {}, saveDraftScores = _.noop, scoringSaveError = ''}) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSaving, setIsSaving] = React.useState(false)
    const [saved, setSaved] = useState(false);

    const onSave = async e => {
        e.preventDefault()
        setIsSaving(true)
        const result = await saveDraftScores(selectedPairingId,selectedScores)
        setIsSaving(false)
        if (result) {
            setSaved(true)
        }
    }

    const history = useHistory()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                const result = await getSelectedPairing(selectedPairingId)
                setIsLoading(false)
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const refreshScores = async () => {
        try {
            setIsLoading(true)
            await getSelectedPairing(selectedPairingId,true)
            setIsLoading(false)
        }catch (e) {
            setIsLoading(false)
        }
    }
    const [selectedScores,setSelectedScores] = useState([])
    const onScoreSelected = (id) => {
        if (selectedScores.includes(id)) {
            setSelectedScores(_.filter(x => id !== x,selectedScores))
        }else {
            setSelectedScores([...selectedScores, id])
        }
    }
    const isScoreSelected = id => selectedScores.includes(id)

    const tooManyOralists = selectedScores => {
        const uniqOralistIdCount = _.pipe(
            _.filter(ds => selectedScores.includes(ds.id)),
            _.flatMap(ds => [ds.scoreData.firstClaimantOralist.id,ds.scoreData.secondClaimantOralist.id,ds.scoreData.firstRespondentOralist.id, ds.scoreData.secondRespondentOralist.id]),
            _.uniq,
            _.size,
        )(selectedPairing.DraftScores)

        return uniqOralistIdCount > 4
    }

    const tooManyScores = _.size(selectedScores) > 3
    const tooLittleScores = _.size(selectedScores) < 3
    const isInvalid = tooLittleScores || tooManyScores || tooManyOralists(selectedScores)

    return (
        <Container maxWidth='xl' className={classes.root}>
            {isLoading &&
                <CircularProgress/>
            }
            {!isLoading &&
                <form onSubmit={onSave}>
                    <Button style={{ margin: '0 0 2rem 0' }} variant='text' onClick={() => history.go(-1)} >
                        <ArrowBackIosIcon /> Back to Oral  Arguments
                    </Button>
                    <Typography variant='h4'>Review Scores</Typography>
                    <Typography variant='h8'>Confirm the submitted scores by selecting three (3) arbitrators and pressing submit.</Typography>

                    <Typography style={{marginTop: '2rem', marginBottom: '2rem'}}> <strong><TeamName team={selectedPairing.claimant} adminUse={true} /> vs. <TeamName team={selectedPairing.respondent} adminUse={true} /></strong> <br></br>({format(utcToZonedTime(selectedPairing.pairingTime, 'Asia/Hong_Kong'), 'iii, dd MMMM hh:mm aaa')})</Typography>

                    <Grid container>
                        <Typography style={{marginBottom: '0'}}>Submissions count: {_.size(selectedPairing.DraftScores)} </Typography>
                        <Button style={{margin: '0 0 0 2rem', padding: '0'}} onClick={refreshScores} variant='text' startIcon={<Cached/>}>Refresh</Button>

                    </Grid>
                    <Grid style={{marginTop: '1rem'}}>
                        <div className='draft-score-headers' style={{paddingLeft: '50px'}}>
                            <div>Arbitrator</div>
                            <div>Claimant</div>
                            <div>Respondent</div>
                        </div>
                        <hr />
                        <div className='draft-scores'>
                            {_.map(s => {
                                return (<div className='draft-score-row' style={{padding: '1.5rem 0 1rem'}} >
                                    <Checkbox
                                        checked={isScoreSelected(s.id)}
                                        onChange={() => onScoreSelected(s.id)}
                                        value={true}
                                        color='primary'
                                    />
                                    <div>
                                        <Typography style={{marginBottom: '0'}}> {s.scoreData.arbitrator} </Typography>
                                        <Typography variant='caption'>(submitted {hongKongTimeFromUTC(s.createdAt, 'EEE, dd MMMM hh:mm aaa')})</Typography>
                                        </div>
                                    <div>
                                        <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.claimant?.name}</Typography>
                                        <div className='draft-score-row-details'>
                                            <Typography>Oralist</Typography>
                                            <Typography>Score</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.firstClaimantOralist?.name}</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.firstClaimantOralistScore}</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.secondClaimantOralist?.name}</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.secondClaimantOralistScore}</Typography>
                                        </div>
                                    </div>


                                    <div>
                                        <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.respondent?.name}</Typography>
                                        <div className='draft-score-row-details'>
                                            <Typography>Oralist</Typography>
                                            <Typography>Score</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.firstRespondentOralist?.name}</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.firstRespondentOralistScore}</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.secondRespondentOralist?.name}</Typography>
                                            <Typography style={{fontWeight: 'bold'}}>{s?.scoreData?.secondRespondentOralistScore}</Typography>
                                        </div>
                                    </div>

                                </div>)
                                },selectedPairing.DraftScores)
                            }
                        </div>
                        <Grid item xs={12} md={12} style={{display:'flex', alignItems: 'center', marginTop: '1rem'}}>
                            <Button variant='contained'
                                    type='submit'
                                    color='primary'
                                    disabled={isSaving || isInvalid}>Submit</Button>
                            {isSaving &&
                                <CircularProgress size={30} style={{marginLeft: '2rem'}}/>
                            }
                        </Grid>
                        { tooManyScores &&
                            <Typography variant='caption'>Too many arbitrators selected. Select only three (3) arbitrators to continue. </Typography>
                        }
                        { tooLittleScores &&
                            <Typography variant='caption'>Select three (3) arbitrators to continue. </Typography>
                        }
                        {tooManyOralists(selectedScores) &&
                            <Typography variant='caption'>Only two (2) unique claimant oralists and two (2) unique respondent oralists can be submitted.</Typography>
                        }
                    </Grid>
                    {scoringSaveError &&
                        <Typography color='error'>{scoringSaveError}</Typography>
                    }
                    <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                        <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                            Scores saved
                        </Alert>
                    </Snackbar>
                </form>

            }
        </Container>
    )
}


ReviewScores.propTypes = {
    selectedPairing: PropTypes.object,
    getSelectedPairing: PropTypes.func,
}

export default connect(
    (state, ownProps) => ({
        selectedPairing: state.admin.selectedPairing,
        selectedPairingId: ownProps.match.params.pairingId,
        scoringSaveError: state.admin.scoringSaveError
    }), {
        getSelectedPairing: getSelectedPairing,
        saveDraftScores: saveDraftScores
    })(ReviewScores)

